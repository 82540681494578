import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { StepDataProps } from '@/components/common/Stepper';

interface CancellationState {
  whyOption?: string;
  whyDetail?: string;
  reviewScore?: string;
  reviewDetail?: string;
  expectationsDetail?: string;
  disccountAccept?: boolean;
  freeAccept?: boolean;
  howOfften?: string;
  improvements?: string;
  improvementsResult?: string;
  stepperNextBtnDisable?: boolean;
  showStepButton?: boolean;
  currentStep?: StepDataProps;
}

const initialState: CancellationState = {
  whyOption: '',
  whyDetail: '',
  reviewScore: '',
  reviewDetail: '',
  expectationsDetail: '',
  disccountAccept: false,
  freeAccept: false,
  howOfften: '',
  improvements: '',
  improvementsResult: '',
  stepperNextBtnDisable: true,
};

const cancellationStateSlice = createSlice({
  name: 'cancellation',
  initialState,
  reducers: {
    setCancellationState: (
      state,
      { payload }: PayloadAction<CancellationState>
    ) => ({
      ...state,
      ...payload,
    }),
    resetCancellationState: () => initialState,
  },
});

export const { setCancellationState, resetCancellationState } =
  cancellationStateSlice.actions;

export default cancellationStateSlice.reducer;

import { combineReducers } from 'redux';

import accountAreaReducer from './reducers/accountAreaReducer';
import applicationReducer from './reducers/applicationReducer';
import authReducer from './reducers/authReducer';
import cancellationReducer from './reducers/cancellationReducer';
import emailFromScratchReducer from './reducers/emailFromScratchReducer';
import fromScratchReducer from './reducers/fromScratchReducer';
import planReducer from './reducers/planReducer';
import productsReducer from './reducers/productsReducer';
import shopifyTemplateFieldsReducer from './reducers/shopifyTemplateFieldsReducer';
import { apiSlice } from './slices/apiSlice';

/**
 * @Todo Fix state name and normalizing state shape
 * Name State Slices Based On the Stored Data
 * {@Link https://redux.js.org/style-guide/style-guide#name-state-slices-based-on-the-stored-data}
 *
 * Normalizing State Shape
 * {@Link https://redux.js.org/usage/structuring-reducers/normalizing-state-shape}
 */
export const appReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  products: productsReducer,
  fromScratch: fromScratchReducer,
  application: applicationReducer,
  emailFromScratch: emailFromScratchReducer,
  plan: planReducer,
  accountArea: accountAreaReducer,
  cancellation: cancellationReducer,
  shopifyTemplateFields: shopifyTemplateFieldsReducer,
});

export const rootReducer = (state: any, action: any) =>
  appReducer(state, action);

export type RootState = ReturnType<typeof rootReducer>;

/* eslint-disable react/no-danger */
/* eslint-disable react-hooks/exhaustive-deps */
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import DiscountPlanPrice from './DiscountPlanPrice';

import Button from '../common/Button';
import { StepDataProps } from '../common/Stepper';

import { cancellationSteps } from '@/shared/configs/cancellationSteppers';
import { setCancellationState } from '@/shared/reducers/cancellationReducer';
import { RootState } from '@/shared/reduxStore';
import {
  useAcceptOfferMutation,
  useLazyGetUserSubscriptionQuery,
  useUserCancelationFeedbackMutation,
} from '@/shared/slices/subscriptionSlice';

function Discount() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state?.auth);
  const {
    currentStep,
    whyOption,
    whyDetail,
    reviewScore,
    reviewDetail,
    expectationsDetail,
  } = useSelector((state: RootState) => state.cancellation);
  const stepperData: StepDataProps[] = cancellationSteps({ t });

  const [cancellationFeedback] = useUserCancelationFeedbackMutation();
  const [acceptOffer] = useAcceptOfferMutation();
  const [getSubscriptionInfo] = useLazyGetUserSubscriptionQuery();

  const customerFeedback = {
    whyOption: whyOption || 'other',
    whyDetail,
    reviewScore,
    reviewDetail,
    expectationsDetail,
  };

  useEffect(() => {
    dispatch(setCancellationState({ showStepButton: false }));
  }, []);

  const handleStepState = (next = true) => {
    if (!currentStep) return;
    const currentStepIndex = _findIndex(stepperData as any, {
      id: currentStep.id,
    });
    const correctStep = next
      ? stepperData[currentStepIndex + 1]
      : stepperData[currentStepIndex - 1];
    dispatch(
      setCancellationState({
        currentStep: correctStep,
        showStepButton: true,
      })
    );
  };

  const handleAccept = async () => {
    // TODO: Calling API to handle logic to update the next month subscription
    dispatch(setCancellationState({ disccountAccept: true }));
    cancellationFeedback(customerFeedback);
    await acceptOffer({ offer: 50 }).unwrap();
    getSubscriptionInfo(undefined, false);
  };

  const discountPercentage = 50;
  return (
    <div className="text-white">
      {!user?.trial && (
        <span
          dangerouslySetInnerHTML={{
            __html: t('cancelation.discountLimitedOffer', {
              interpolation: { escapeValue: false },
              discount: discountPercentage,
            }),
          }}
        />
      )}
      <DiscountPlanPrice discountPercentage={discountPercentage} />
      <div className="flex justify-between mt-10">
        <Button
          className="py-3 mr-3 text-white bg-transparent border-white border-solid px-7 border-1 rounded-xl"
          text={t('common.back')}
          onClick={() => handleStepState(false)}
        />
        <div>
          <Button
            className="py-3 mr-3 text-white bg-transparent border-white/30 border-solid px-7 border-1 rounded-xl"
            text={t('common.decline')}
            onClick={handleStepState}
          />
          <Button
            className="py-3 px-7 text-white border-1 border-solid border-primary bg-primary rounded-xl"
            text={t('common.accept')}
            onClick={handleAccept}
          />
        </div>
      </div>
    </div>
  );
}

export default Discount;

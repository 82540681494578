import { camelCase, uniqueId } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IoAddOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';

import { ServiceV3 } from '../../../shared/configs/servicesMap';
import { ServiceContent, ServiceContentItem } from '../ContentAccordion';

import Button from '@/components/common/Button';
import TextLoadingState from '@/components/common/TextLoadingState';
import { FeatureFlags, displayFeature } from '@/shared/configs/featureFlags';
import { setFromScratch } from '@/shared/reducers/fromScratchReducer';
import { RootState } from '@/shared/rootReducers';

interface ContentV3Props extends ServiceV3 {
  serviceData: Record<string, any>[];
  userPlan: string;
  selectedService: string;
}

function ContentV3({
  selectedService,
  blurredFor,
  title,
  serviceData,
  generateFromScratch,
  userPlan,
  expandAll = false,
}: ContentV3Props) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();
  const { showScratchButton } = useSelector(
    (state: RootState) => state.fromScratch
  );
  const { productsRegenerate, titleRegenerate } = useSelector(
    (state: RootState) => state?.products
  );

  const displayGenerateFromSratchProduction = displayFeature({
    featureFlag: FeatureFlags?.PRODLY_272_GENERATE_FROM_SCRATCH_20240411,
  });

  if (serviceData?.length <= 0) return <TextLoadingState />;

  const defaultValue = expandAll
    ? serviceData.map((data) => data.title)
    : [serviceData?.[0]?.title ?? ''];
  const [currentTitle]: any = title;
  return (
    <div className="w-full max-w-[800px]">
      <h2 className="mb-5 font-semibold text-left underline ">
        {currentTitle?.[language]}
      </h2>
      {generateFromScratch && displayGenerateFromSratchProduction && (
        <div>
          <Button
            text="Generate from scratch"
            className="bg-primary text-white text-sm shadow-[0_0px_20px] shadow-primary/50 px-2 py-4 outline-none border-none rounded-xl disabled:opacity-20 disabled:shadow-none disabled:cursor-not-allowed"
            icon={<IoAddOutline className="text-xl" />}
            iconPosition="before"
            onClick={() => dispatch(setFromScratch({ active: true }))}
            disabled={!showScratchButton}
          />
        </div>
      )}
      {/* Content suggestion */}
      <ServiceContent defaultValue={defaultValue} className="mt-10">
        {serviceData &&
          serviceData.map(
            ({ title: partTitle, content, id: generateContentId }: any) => {
              // Patching the title langugage for old product
              const titleLanguage = t(`product.${camelCase(partTitle)}`);
              return (
                <ServiceContentItem
                  selectedService={selectedService}
                  key={uniqueId()}
                  title={titleLanguage}
                  content={content}
                  isHide={blurredFor.includes(userPlan)}
                  generateContentId={generateContentId}
                  isDisableRegenerateButtom={productsRegenerate}
                  titleRegenerate={titleRegenerate}
                />
              );
            }
          )}
      </ServiceContent>
    </div>
  );
}

export default ContentV3;

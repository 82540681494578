/* eslint-disable no-alert */
/* eslint-disable react-hooks/exhaustive-deps */
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoCloseCircleSharp } from 'react-icons/io5';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import DiscountPlanPrice from './DiscountPlanPrice';

import Button from '../common/Button';
import { StepDataProps } from '../common/Stepper';

import cancelSubscription from '@/services/subscription.service';
import { cancellationSteps } from '@/shared/configs/cancellationSteppers';
import { setCancellationState } from '@/shared/reducers/cancellationReducer';
import { RootState } from '@/shared/reduxStore';
import { useLazyGetGenerateNewTokenQuery } from '@/shared/slices/authSlice';
import {
  useAcceptOfferMutation,
  useLazyGetUserSubscriptionQuery,
  useUserCancelationFeedbackMutation,
} from '@/shared/slices/subscriptionSlice';

function Pause() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    currentStep,
    whyOption,
    whyDetail,
    reviewScore,
    reviewDetail,
    expectationsDetail,
    howOfften,
    improvements,
    improvementsResult,
  } = useSelector((state: RootState) => state.cancellation);
  const stepperData: StepDataProps[] = cancellationSteps({ t });
  const navigate = useNavigate();
  const [successModal, setSuccessModal] = useState(false);

  const { user } = useSelector((state: RootState) => state?.auth);
  const [getSubscriptionInfo] = useLazyGetUserSubscriptionQuery();
  const [cancellationFeedback] = useUserCancelationFeedbackMutation();
  const [generateNewToken] = useLazyGetGenerateNewTokenQuery();
  const [acceptOffer] = useAcceptOfferMutation();

  const customerFeedback = {
    whyOption: whyOption || 'other',
    whyDetail,
    reviewScore,
    reviewDetail,
    expectationsDetail,
    howOfften,
    improvements,
    improvementsResult,
  };

  useEffect(() => {
    dispatch(setCancellationState({ showStepButton: false }));
  }, []);

  const handleStepState = (next = true) => {
    if (!currentStep) return;
    const currentStepIndex = _findIndex(stepperData as any, {
      id: currentStep.id,
    });
    const correctStep = next
      ? stepperData[currentStepIndex + 1]
      : stepperData[currentStepIndex - 1];
    dispatch(
      setCancellationState({
        currentStep: correctStep,
        showStepButton: true,
      })
    );
  };

  const deleteSubscription = async () => {
    const response = await cancelSubscription(user?.id);
    if (response) {
      toast.success('success');
    }
    generateNewToken(undefined, false);
    getSubscriptionInfo(undefined, false);
  };

  const handleAccept = async () => {
    // TODO: Calling API to handle logic to update the next month subscription
    dispatch(setCancellationState({ freeAccept: true }));
    cancellationFeedback(customerFeedback);
    await acceptOffer({ offer: 100 }).unwrap();
    setSuccessModal(true);
    getSubscriptionInfo(undefined, false);
  };

  const handleDecline = () => {
    cancellationFeedback(customerFeedback);
    setSuccessModal(true);
    deleteSubscription();
  };

  return (
    <div className="text-white">
      <p className="leading-6">{t('account.pauseStepNotification')}</p>
      <DiscountPlanPrice discountPercentage={100} />
      <div className="flex justify-between mt-10">
        <Button
          className="py-3 mr-3 text-white bg-transparent border-white border-solid px-7 border-1 rounded-xl"
          text={t('common.back')}
          onClick={() => handleStepState(false)}
        />
        <div>
          <Button
            className="py-3 mr-3 text-white bg-transparent border-white/30 border-solid px-7 border-1 rounded-xl"
            text={t('common.decline')}
            onClick={handleDecline}
          />
          <Button
            className="py-3 px-7 text-white border-1 border-solid border-primary bg-primary rounded-xl"
            text={t('common.accept')}
            onClick={handleAccept}
          />
        </div>
        <Modal
          className="modal-container"
          isOpen={successModal}
          overlayClassName="modal-overlay"
        >
          <div className="rounded-lg md:min-w-full text-sm lg:text-base w-46 p-6 px-10 bg-white relative">
            <Button
              text=""
              className="bg-transparent border-0 outline-none !justify-end absolute top-1 right-0"
              icon={<IoCloseCircleSharp className="text-3xl" />}
              onClick={() => {
                setSuccessModal(false);
                navigate('/account');
              }}
            />
            <div className="flex items-center justify-center font-semibold text-xl">
              {t('account.cancelationFeedbackSuccess')}
            </div>
            <div className="flex justify-center mt-4">
              <Button
                className="p-2 px-8 mr-2 text-white border-none rounded-lg bg-primary"
                text={t('common.backToDashboard')}
                type="button"
                onClick={() => {
                  setSuccessModal(false);
                  navigate('/account');
                }}
              />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default Pause;

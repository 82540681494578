/* eslint-disable react/forbid-dom-props */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosInfinite } from 'react-icons/io';
import { useDispatch } from 'react-redux';

import Button from '../common/Button';

import { displayFeature, FeatureFlags } from '@/shared/configs/featureFlags';
import { setAccountAreaState } from '@/shared/reducers/accountAreaReducer';

interface compteDataProps {
  user: any;
  generatedProducts: number;
  regeneratedProducts: number;
  stock: number;
  maxProductsForPlan: (userPlan: string) => number;
  maxRegenerationsForPlan: (userPlan: string) => number;
  progressBarWidth: () => number;
  progressRegBarWidth: (current: number, max: number) => number;
  handlePasswordChange: () => () => void;
}

function Compte({
  user,
  generatedProducts,
  regeneratedProducts,
  stock,
  maxProductsForPlan,
  maxRegenerationsForPlan,
  progressBarWidth,
  progressRegBarWidth,
  handlePasswordChange,
}: compteDataProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'account' });
  const dispatch = useDispatch();
  const displayUXAccountPageProduction = displayFeature({
    featureFlag: FeatureFlags.PRODLY_302_UX_ACCOUNT_PAGE_20240515,
  });

  const { plan, shopify, shopifyUrl } = user ?? {};

  const productGenerate = stock ?? maxProductsForPlan(plan);
  const isLifetimePlan = user?.plan === 'Lifetime';
  return (
    <div className="flex flex-col">
      {/* Title */}
      <div className="relative pb-1 text-white">
        <h1 className="after:content-[''] after:absolute after:bottom-0 after:left-[-15px] after:bg-gradient-to-r after:right-0 after:h-[1px] after:from-transparent after:from-[0%] after:via-[rgba(255,255,255,0.2)] after:via-[20%] after:to-transparent after:to-[100%]">
          {t('compte')}
        </h1>
      </div>
      {/* Progress Bar For Generations */}
      <div className="mt-10 font-semibold">
        <span className="flex items-center">
          {t('produits')} : {generatedProducts} /
          {isLifetimePlan ? (
            <IoIosInfinite className="w-[19px] h-fit ml-1" />
          ) : (
            productGenerate
          )}
        </span>
        {!isLifetimePlan && (
          <div className="w-full h-[16px] rounded-[4px] bg-[rgba(255,255,255,0.15)] my-2">
            <div
              className="my-2 rounded-[4px] h-full bg-gradient-to-r from-[#8628C0] to-[#A238D5]"
              style={{ width: `${progressBarWidth()}%` }}
            />
          </div>
        )}
      </div>
      {/* Progress Bar For Re Generations */}
      <div className="mb-5 font-semibold">
        <span className="flex items-center">
          {t('regenerations')} : {regeneratedProducts} /
          {isLifetimePlan ? (
            <IoIosInfinite className="w-[19px] h-fit ml-1" />
          ) : (
            maxRegenerationsForPlan(plan)
          )}
        </span>
        {!isLifetimePlan && (
          <div className="w-full h-[16px] rounded-[4px] bg-[rgba(255,255,255,0.15)] my-2">
            <div
              className="my-2 rounded-[4px] h-full bg-gradient-to-r from-[#8628C0] to-[#A238D5]"
              style={{
                width: `${progressRegBarWidth(regeneratedProducts, maxRegenerationsForPlan(plan))}%`,
              }}
            />
          </div>
        )}
      </div>
      {/* Change password button */}
      <div className="font-semibold">
        <h3>{t('motDePasse')}</h3>
        <Button
          type="button"
          text={t('motDePasseOublie')}
          className="w-full p-3 mb-5 font-bold text-white bg-gradient-to-r from-[#8628C0] to-[#A238D5] border-0 rounded-md"
          onClick={handlePasswordChange}
        />
      </div>
      {!!shopify && (
        <div className="font-semibold">
          <h3>{t('shopifyConnect')}</h3>
          <Button
            type="button"
            text={`${t('disconnect')} ${shopifyUrl}`}
            className="w-full p-3 mb-5 font-bold text-white bg-shopifyDark border-0 rounded-md"
            onClick={() =>
              dispatch(
                setAccountAreaState({
                  shopifyDisconnectionModal: true,
                })
              )
            }
          />
        </div>
      )}
      {displayUXAccountPageProduction && (
        <div>
          <h3 className="font-semibold text-white">{t('brandVoice')}</h3>
          <Button
            type="button"
            className="w-full p-3 mb-7 font-bold text-white bg-black border-[1px] border-[#8628C0] rounded-md"
            text={t('seeBrandVoice')}
          />
        </div>
      )}
    </div>
  );
}

export default Compte;

/* eslint-disable react-hooks/exhaustive-deps */
import Animation from 'components/common/Animation';
import _drop from 'lodash/drop';
import _head from 'lodash/head';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import './app.css';
import { useNavigate } from 'react-router-dom';

import Button from '@/components/common/Button';
import Skeleton from '@/components/common/Skeleton';
import Stepper, { StepDataProps } from '@/components/common/Stepper';
import Header from '@/components/Header';
import { cancellationSteps } from '@/shared/configs/cancellationSteppers';
import {
  setCancellationState,
  resetCancellationState,
} from '@/shared/reducers/cancellationReducer';
import { RootState } from '@/shared/reduxStore';
import { useGetUserSubscriptionQuery } from '@/shared/slices/subscriptionSlice';
import { SubscriptionStatus } from '@/shared/types/user';

function Cancellation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const stepperData = cancellationSteps({ t });
  const { user } = useSelector((state: RootState) => state?.auth);
  const { subscriptionCanceled } = useSelector(
    (state: RootState) => state?.accountArea
  );
  const {
    stepperNextBtnDisable,
    showStepButton = true,
    currentStep = stepperData[0],
    disccountAccept = false,
    freeAccept = false,
  } = useSelector((state: RootState) => state.cancellation);

  const { data: userSubscription, isFetching } = useGetUserSubscriptionQuery();
  const { status, offerAccept } = userSubscription ?? {};

  const setCurrentStep = (stepState: StepDataProps) => {
    if (!stepState) return;
    dispatch(setCancellationState({ currentStep: stepState }));
  };

  useEffect(() => {
    if (!user) return;

    if (
      subscriptionCanceled ||
      offerAccept !== 0 ||
      Object.values(SubscriptionStatus).includes(status)
    ) {
      navigate('/account');
    }
  }, [user, userSubscription]);

  useEffect(
    () => () => {
      dispatch(resetCancellationState());
      dispatch(setCancellationState({ currentStep: stepperData[0] }));
    },
    []
  );

  useEffect(() => {
    const currentStepData = stepperData?.find(
      (step) => step.id === currentStep?.id
    );
    if (!currentStepData) return;
    dispatch(setCancellationState({ currentStep: currentStepData }));
  }, [language]);

  return (
    <div>
      <div className="absolute w-full h-full pointer-events-none bg-change-password-gradient-background" />
      <Header />
      {isFetching ? (
        <div className="form-container">
          <div className="space-y-2 border border-primary/20 rounded-lg border-solid p-10">
            <Skeleton className="col-span-2 rounded h-[50px] w-[800px]" />
            <Skeleton className="col-span-2 rounded h-[20px] w-[400px]" />
            <Skeleton className="col-span-2 rounded h-[20px] w-[250px]" />
            <Skeleton className="col-span-2 rounded h-[20px] w-[200px]" />
            <Skeleton className="col-span-2 rounded h-[20px] w-[200px]" />
            <Skeleton className="col-span-2 rounded h-[20px] w-[100px]" />
            <Skeleton className="col-span-2 rounded h-[20px] w-[80px]" />
            <Skeleton className="col-span-2 rounded h-[20px] w-[80px]" />
          </div>
        </div>
      ) : (
        <Animation className="form-container">
          <div className="w-full max-w-[800px] border border-primary/20 rounded-lg border-solid p-10 text-white">
            {disccountAccept || freeAccept ? (
              <>
                <h1>{t('cancelation.thanksToStaying')}</h1>
                <p className="leading-6">
                  {t('cancelation.acceptOfferThanks')}
                </p>
                <Button
                  className="py-3 px-7 text-white border-1 border-solid border-primary bg-primary rounded-xl"
                  text={t('common.toMyDashboard')}
                  onClick={() => navigate('/app')}
                />
              </>
            ) : (
              <Stepper
                showProgress={false}
                showStepHeader
                stepData={stepperData}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                nextBtnDisabled={stepperNextBtnDisable}
                showStepButton={showStepButton}
                stepMarkOnTop
                lastStepAction={() => {}}
              />
            )}
          </div>
        </Animation>
      )}
    </div>
  );
}

export default Cancellation;

/* eslint-disable react-hooks/exhaustive-deps */
import _find from 'lodash/find';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Label } from '../common/Label';
import { RadioGroup, RadioGroupItem } from '../common/shadcn-ui/RadioGroup';

import { setCancellationState } from '@/shared/reducers/cancellationReducer';
import { RootState } from '@/shared/reduxStore';

type RadioSettingProps = {
  id: string;
  label: string;
};

function ImprovementsFollowUp() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { improvements = '', improvementsResult = '' } = useSelector(
    (state: RootState) => state.cancellation
  );

  const radiosSettings: RadioSettingProps[] = [
    {
      id: 'yes',
      label: t('common.yes'),
    },
    {
      id: 'no',
      label: t('common.no'),
    },
  ];

  const handleOptionChange = (value: string) => {
    dispatch(
      setCancellationState({
        improvementsResult: value,
      })
    );
  };

  useEffect(() => {
    dispatch(
      setCancellationState({ stepperNextBtnDisable: improvementsResult === '' })
    );
  }, [improvementsResult]);

  return (
    <div className="mt-10 text-white">
      <RadioGroup
        defaultValue={improvementsResult}
        className="text-white"
        onValueChange={handleOptionChange}
      >
        {radiosSettings.map(({ id, label }) => (
          <div className="flex items-center space-x-2">
            <RadioGroupItem value={id} id={id} />
            <Label htmlFor={id}>{label}</Label>
          </div>
        ))}
      </RadioGroup>
      <h4 className="mt-10 mb-2">{t('cancelation.desireFeature')}</h4>
      <p className="italic text-sm text-orange-200 m-0">{improvements}</p>
    </div>
  );
}

export default ImprovementsFollowUp;

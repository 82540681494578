import _findIndex from 'lodash/findIndex';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosInfinite } from 'react-icons/io';
import {
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
} from 'react-icons/io5';
import { useSelector } from 'react-redux';

import Button from '../common/Button';
import { Badge } from '../common/shadcn-ui/Badge';
import { useFeaturesHook } from '../hooks/useFeaturesHook';
import { CheckoutHandleProps } from '../hooks/useUpgradePlan';

import { cn } from '@/lib/utils';
import { currencySign } from '@/shared/currency';
import { RootState } from '@/shared/reduxStore';
import { PlansLevel } from '@/shared/types/user';

interface PlanItemProps {
  isAnnualAccount: boolean;
  isAnnualButton: boolean;
  planIndex: number;
  plan: Record<string, any>;
  handleUpgradePlan: (args: CheckoutHandleProps) => void;
  checkoutLoading: boolean;
  isTrialEnable?: boolean;
  shopifyPurchase?: boolean;
}

function PlanItem({
  isAnnualAccount,
  isAnnualButton,
  planIndex,
  plan,
  checkoutLoading,
  handleUpgradePlan,
  isTrialEnable = undefined,
  shopifyPurchase = false,
}: PlanItemProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation('translation', { keyPrefix: 'upgrade' });
  const { user } = useSelector((state: RootState) => state?.auth);
  const { currency } = useSelector((state: RootState) => state?.plan);
  const featuresText = useFeaturesHook();
  const {
    expiration,
    subscription_id: subscriptionId,
    subscription_end: subscriptionEnd,
    trial,
  } = user || {};

  const userPlanLevel =
    (PlansLevel[user?.plan ?? 'free'] as unknown as number) ?? null;
  if (userPlanLevel === null) {
    return <p>Loading...</p>;
  }
  let trialAvailable = isTrialEnable;
  if (trialAvailable === undefined) {
    trialAvailable = !trial && !subscriptionId && !subscriptionEnd;
  }
  const planLevel = planIndex + 1;

  const isExpired = expiration === true;
  const isTrial = user?.trial === 1;
  // Disable conditionally at monthly sub
  let isPlanDisabled = userPlanLevel >= planLevel && !isExpired && !isTrial;
  // If the current sub is not annual, then not disable the buy button when switching to annual plans
  if (isAnnualButton && !isAnnualAccount) {
    isPlanDisabled = false;
  }
  // Disable all buy button when on the Lifetime level
  if (userPlanLevel === PlansLevel.Lifetime) {
    isPlanDisabled = true;
  }

  const monthlyPrice = currency
    ? plan.monthlyPrice?.[currency] && plan.monthlyPrice?.[currency].toFixed(2)
    : plan.monthlyPrice.usd;
  const annualyPrice = currency
    ? plan.annualPrice?.[currency] && plan.annualPrice?.[currency].toFixed(2)
    : plan.annualPrice.usd;
  const purchaseButtonText = !trialAvailable ? t('upgrade') : t('tryForFree');
  return (
    <div
      className={cn(
        'box-border justify-end relative bg-defaultBlackLight rounded-lg text-white flex flex-col items-center p-4 xs:w-full lg:w-fit',
        plan.name.toLowerCase(),
        isPlanDisabled && 'opacity-50'
      )}
    >
      {plan.name === 'Pro' && (
        <div
          className={cn(
            'top-seller',
            language === 'fr' && '!transform-none !left-[21%]'
          )}
        >
          {t('topSeller')}
        </div>
      )}
      {plan.name === 'Lifetime' && (
        <Badge className="rounded-sm absolute -top-2 text-base uppercase">
          {t('lifetimeOffer')}
        </Badge>
      )}
      <h3 className="plan-name">{plan.name}</h3>
      <div className="price-container">
        {plan.name !== 'Lifetime' ? (
          <>
            <div
              className={cn(
                isAnnualButton ? 'original-price' : 'discounted-price'
              )}
            >
              {monthlyPrice}
              {currency ? currencySign[currency] : '$'}
              <span> / {t('month')}</span>
            </div>
            {isAnnualButton && (
              <div className="discounted-price">
                {annualyPrice} {currency ? currencySign?.[currency] : '$'}
                <span> / {t('month')}</span>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="original-price">
              {currency
                ? plan.originalPrice?.[currency]
                : plan.originalPrice.usd}{' '}
              {currency ? currencySign?.[currency] : '$'}
            </div>
            <div className="discounted-price">
              {currency
                ? plan.discountPrice?.[currency]
                : plan.discountPrice.usd}{' '}
              {currency ? currencySign?.[currency] : '$'}
            </div>
          </>
        )}
      </div>
      <ul className="features-list">
        {featuresText?.map((feature, fIndex) => {
          const isFeatureBoolean = typeof plan.features[fIndex] === 'boolean';
          const featureValue = plan.features[fIndex];
          return (
            <li key={feature} className="feature">
              {isFeatureBoolean &&
                (featureValue ? (
                  <IoCheckmarkCircleOutline className="mr-3.5 text-success w-[24px] h-[24px]" />
                ) : (
                  <IoCloseCircleOutline className="mr-3.5 text-red-500 w-[24px] h-[24px]" />
                ))}
              {!isFeatureBoolean && (
                <>
                  <IoCheckmarkCircleOutline className="mr-3.5 text-success w-[24px] h-[24px]" />
                  <span>
                    {typeof featureValue === 'number' && featureValue >= 999 ? (
                      <IoIosInfinite className="w-[24px] h-fit mr-2" />
                    ) : (
                      featureValue
                    )}
                    &nbsp;
                  </span>
                </>
              )}
              {feature}
            </li>
          );
        })}
      </ul>
      <Button
        type="button"
        className={cn(
          'buy-button hover-button',
          (isPlanDisabled || checkoutLoading) &&
            '!bg-none !shadow-none !bg-[#353535] !cursor-default'
        )}
        onClick={() =>
          handleUpgradePlan({
            planName: plan.name,
            isAnnual: isAnnualButton,
            isPlanDisabled,
            trialEnable: trialAvailable,
            shopifyPurchase,
            currency,
          })
        }
        disabled={checkoutLoading}
        text={isPlanDisabled ? t('unavailable') : purchaseButtonText}
      />
      {trialAvailable && !user?.subscription_id_previous && (
        <span className="sept-jours">
          {`${t('tryItFreeFor')} 3 ${t('days')}*`}
        </span>
      )}
    </div>
  );
}

export default PlanItem;

import { nanoid } from '@reduxjs/toolkit';
import { lowerCase } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ReviewsProps {
  reviews: any[];
  productServiceDetail: any;
}

function Reviews(props: ReviewsProps) {
  const { t } = useTranslation();
  const { reviews, productServiceDetail } = props;
  function csvEscape(str: any) {
    if (typeof str !== 'string') {
      return str; // If it's a number or boolean, it doesn't need escaping
    }
    return `"${str.replace(/"/g, '""')}"`; // Escape any " as "" and wrap the whole thing in quotes
  }
  function exportCSV() {
    let csvContent = 'data:text/csv;charset=utf-8,';
    const header =
      'product_handle,rating,author,email,body,created_at,photo_url,verified_purchase\n';
    csvContent += header;

    reviews.forEach((review) => {
      let mediaLink = review.media;

      // If the media is 'null', replace it with an empty string
      if (mediaLink === 'null') {
        mediaLink = '';
      }

      // If there's a photo link and it contains '._SY88', remove it from the link
      else if (mediaLink && mediaLink.includes('._SY88')) {
        mediaLink = mediaLink.replace('._SY88', '');
      }
      const productHandle = lowerCase(productServiceDetail.name).replaceAll(
        ' ',
        '-'
      );
      const row = [
        csvEscape(productHandle || ''),
        csvEscape(review.rating),
        csvEscape(review.author),
        csvEscape(review.email || ' '),
        csvEscape(review.content.replace(/(\r\n|\n|\r|#)/gm, '')),
        csvEscape(review.datee),
        csvEscape(mediaLink),
        csvEscape('TRUE'),
      ];
      csvContent += `${row.join(',')}\n`;
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'loox_reviews.csv');
    document.body.appendChild(link); // Required for Firefox

    link.click(); // This will download the file
  }

  return (
    <div className="max-w-[800px] ">
      <h3 className="text-xl underline my-[28px]">
        {t('memberArea.serviceMenus.reviews')}
      </h3>

      <button
        type="button"
        className="border border-greyBorder/30 h-[45px] bg-greyBg/10 text-white rounded-[5px] p-2.5 uppercase tracking-[1px] cursor-pointer transition-colors duration-300  ease-linear outline-none"
        onClick={exportCSV}
      >
        {t('memberArea.exportForLoox')}
      </button>
      {reviews && reviews?.length > 0 ? (
        reviews?.map((review) => (
          <div
            key={nanoid()}
            className="card-border p-[15px] my-[15px] rounded-[5px] bg-[#333333] shadow-[0px_3px_10px_rgba(0,0,0,0.1)] "
          >
            <h2 className="mb-10 font-semibold text-center">{review.title}</h2>
            <div className="stars">
              {Array(review.rating).fill(<span>⭐</span>)}{' '}
            </div>
            <p>{review.content}</p>
            {review.verified_purchase && (
              <span className="verified-badge">Verified Purchase</span>
            )}
          </div>
        ))
      ) : (
        <p>No reviews found for this product.</p>
      )}
    </div>
  );
}

export default Reviews;

/* eslint-disable react/no-danger */
import clsx from 'clsx';
import { camelCase } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Service } from '../../../shared/configs/servicesMap';

import Button from '@/components/common/Button';
import ReloadSvgIcon from '@/components/common/svg/ReloadSvgIcon';
import { RootState } from '@/shared/reduxStore';
import { useLazyGetServiceDetailQuery } from '@/shared/slices/productSlice';
import formatLineBreaks from '@/utils/format';

interface ContentProps extends Service {
  serviceKey: string;
  userPlan: string;
  productServiceDetail: any;
}

function SectionDetails({
  blurredFor,
  subSrvice,
  serviceKey,
  title,
  withH2,
  withoutRegenerate,
  userPlan,
  productServiceDetail,
}: ContentProps) {
  const { t } = useTranslation();
  const { currentProductId: productId } = useSelector(
    (state: RootState) => state?.products
  );

  const [isLoading, setIsLoading] = useState(false);

  const [getServiceDetail] = useLazyGetServiceDetailQuery();

  const updateProductContent = async (content: any) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.VITE_APP_API_URL}/regen/${productId}?content=${content}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('productly')}`,
          },
        }
      );

      await response.json();
      getServiceDetail({ productId, service: serviceKey }, false);
      setIsLoading(false);

      // TODO: increment the 'regenerations' count
      // user.regenerations += 1;
      // refreshSubscriptionInfo();
    } catch (error) {
      setIsLoading(false);
    }
  };

  const navigate = useNavigate();
  return (
    <div className="w-full max-w-[800px]">
      {withH2 && (
        <h2 className="mb-10 font-semibold text-center underline ">{title}</h2>
      )}
      {subSrvice.map(({ title: subTitle, key }) => {
        const content = productServiceDetail?.[key];
        if (!content) return <p />;

        const contentPartitionLength = 8;
        const contentFirst = formatLineBreaks(
          content?.slice(0, Math.floor(content.length / contentPartitionLength))
        );
        const contentBlurred = formatLineBreaks(
          content?.slice(contentPartitionLength)
        );

        const subTitleLanguage = t(`product.${camelCase(subTitle)}`);

        return (
          <div key={key} className="w-full">
            <div className="flex items-center justify-between">
              <h3 className="underline font-bold text-[20px]    my-[30px]">
                {subTitleLanguage}
              </h3>
              {!withoutRegenerate && (
                <Button
                  text=""
                  className={clsx(
                    'border border-greyBorder h-[45px] bg-greyBg/10 text-white rounded-[5px] p-2.5 uppercase tracking-[1px] cursor-pointer transition-colors duration-300  ease-linear outline-none',
                    isLoading && 'pointer-events-none opacity-50'
                  )}
                  onClick={() => {
                    if (!isLoading) {
                      updateProductContent(key);
                    }
                  }}
                  disabled={isLoading}
                  icon={<ReloadSvgIcon isLoading={!!isLoading} />}
                />
              )}
            </div>
            {blurredFor.includes(userPlan) ? (
              <div className="relative">
                <div className="clear">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: contentFirst,
                    }}
                  />
                </div>

                <div className=" blur-[8px] ">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: contentBlurred,
                    }}
                  />
                </div>
                <Button
                  className="border border-solid border-[#ffffff4d] rounded-sm uppercase outline-none py-3 px-4 absolute top-1/2 left-[40%] bg-white text-white bg-opacity-5"
                  text="Mise à niveau"
                  onClick={() => navigate('/upgrade')}
                />
              </div>
            ) : (
              <p
                dangerouslySetInnerHTML={{
                  __html: formatLineBreaks(content),
                }}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}

export default SectionDetails;

/* eslint-disable react/no-danger */
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdModeEdit } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { FormFieldsSettingProps } from '../app/shopify/ImportTemplate';
import { Input } from '../common/Input';
import { InputDiv } from '../common/InputDiv';
import { Button } from '../common/shadcn-ui/Button';
import { Textarea } from '../common/shadcn-ui/Textarea';
import Skeleton from '../common/Skeleton';

import { RootState } from '@/shared/rootReducers';
import { useRegenerateShopifyTemplateDataMutation } from '@/shared/slices/shopifySlice';
import cn from '@/utils/cn';

interface EditableTextProps extends FormFieldsSettingProps {
  onInputChange: ({ value, id, name }: any) => void;
}

function EditableText({
  id,
  name,
  text,
  textClass,
  editFieldClass,
  displayBtnClass,
  inputType = 'input',
  onInputChange,
  editable = true,
}: EditableTextProps) {
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState('');
  const [inputChange, setInputChange] = useState<Record<string, any>>({});

  const { t } = useTranslation('translation');

  const { currentProductId, shopifyHomepageImportTemplate } = useSelector(
    (state: RootState) => state?.products
  );

  const [regenerateShopifyTemplateContent, { isLoading: regenerateLoading }] =
    useRegenerateShopifyTemplateDataMutation();

  const handleValueChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let { value: inputValue, id: inputId, name: inputName } = event.target;
    if (!inputValue || inputValue === '') {
      inputValue =
        event.currentTarget.innerText.split('\n').join('</br>') ?? '';
      inputId = (event.currentTarget?.id as string) ?? '';
      inputName =
        (event.currentTarget?.getAttribute('data-name') as string) ?? '';
    }
    if (!inputValue || inputValue?.length <= 0) {
      setError(t('memberArea.UnemptyField'));
      return;
    }
    setError('');
    setInputChange({ value: inputValue, id: inputId, name: inputName });
  };

  const regenerateContent = async ({ id: contentId, type }: any) => {
    const productId = currentProductId ?? 0;
    if (!id || !type || !productId) return;

    const regenerateResponse: Record<string, any> =
      await regenerateShopifyTemplateContent({
        id: contentId,
        type,
        productId,
        homepage: shopifyHomepageImportTemplate ? 1 : 0,
      })?.unwrap();

    const {
      success,
      type: fieldName,
      data: regenerateData,
    } = regenerateResponse ?? {};

    if (!success || !regenerateData) {
      toast.error(t('memberArea.regenerateContentFailed'));
      return;
    }

    let fieldNameByType = fieldName;

    /*
      If regen field is title, meaning content also need update
        - Update content
        - Update title
    */
    if (fieldName.includes('Title')) {
      fieldNameByType = fieldNameByType.replace('Title', 'Content');
      onInputChange({ value: regenerateData.title, name: fieldName });
      onInputChange({ value: regenerateData.content, name: fieldNameByType });
      return;
    }

    /*
      If title is not null
        - Update content
        - Update title
    */
    if (regenerateData?.title && fieldName.includes('Content')) {
      fieldNameByType = fieldNameByType.replace('Content', 'Title');
      onInputChange({ value: regenerateData.title, name: fieldNameByType });
      onInputChange({ value: regenerateData.content, name: fieldName });
      return;
    }

    onInputChange({ value: regenerateData.content, name: fieldName });
  };

  if (regenerateLoading) {
    return (
      <div className="w-full">
        <div className="product-name-head w-150 md:w-200 xl:w-250 space-y-2">
          <Skeleton className="w-full h-16" />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full">
      {!editable && (
        <div
          className={cn(
            textClass,
            inputType === 'input' && 'text-defaultBlack h-auto mt-2',
            inputType === 'textarea' && 'box-border mt-2 border'
          )}
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      )}
      {editable && !edit && (
        <Button
          type="button"
          onClick={() => setEdit(true)}
          className={cn(
            'pt-3 border-0 text-wrap text-left p-0 m-0 bg-transparent hover:bg-transparent shadow-none cursor-pointer h-fit',
            displayBtnClass
          )}
        >
          <div className="flex hover:border hover:border-dashed hover:border-zinc-400 [&>_svg]:hover:flex">
            <p
              className={cn('w-fit m-0', textClass)}
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            />
            <MdModeEdit className="text-lg text-primary hidden ml-2 w-[24px]" />
          </div>
        </Button>
      )}
      {edit && (
        <>
          {inputType === 'input' && (
            <Input
              className={cn(
                'text-defaultBlack indent-3 h-auto mt-2',
                editFieldClass
              )}
              defaultValue={text}
              onChange={handleValueChange}
              id={id}
              name={name}
            />
          )}
          {inputType === 'textarea' && (
            <Textarea
              className={cn('box-border mt-2 border', editFieldClass)}
              defaultValue={text}
              onChange={handleValueChange}
              id={id}
              name={name}
            />
          )}
          {inputType === 'contentEditable' && (
            <InputDiv
              id={id}
              data-name={name}
              maxLength={500}
              className={cn(
                'box-border mt-2 border border-solid p-2 outline-none text-sm',
                editFieldClass
              )}
              onBlur={handleValueChange}
              value={
                <span
                  className={cn('w-fit m-0', textClass)}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: text,
                  }}
                />
              }
            />
          )}
          <span className="text-red-600 text-xs">{error}</span>
          <div className="flex justify-end mt-2">
            <Button
              type="button"
              onClick={() => {
                setEdit(false);
                setError('');
              }}
              className="text-zinc-600 border-0 bg-zinc-200 hover:bg-zinc-100 text-xs py-2 h-fit shadow-none mr-2"
            >
              {t('memberArea.cancel')}
            </Button>
            <Button
              type="button"
              onClick={() => {
                setEdit(false);
                if (!inputChange) return;
                setError('');
                onInputChange(inputChange);
              }}
              className="text-white border-0 text-xs py-2 h-fit shadow-none"
            >
              {t('common.save')}
            </Button>
            <Button
              type="button"
              onClick={() => {
                setEdit(false);
                regenerateContent({
                  id,
                  type: name,
                });
              }}
              className={cn(
                'text-white border-0 text-xs py-2 h-fit shadow-none ms-2',
                (name === 'productName' || name === 'totalReviews') && 'hidden'
              )}
            >
              {t('memberArea.regenerate')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export default EditableText;

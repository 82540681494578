/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import TextLoadingSkeleton from '../common/TextLoadingSkeleton';

import { currencySign } from '@/shared/currency';
import { RootState } from '@/shared/reduxStore';
import { useGetUserSubscriptionQuery } from '@/shared/slices/subscriptionSlice';

function DiscountPlanPrice({
  discountPercentage,
}: {
  discountPercentage: number;
}) {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state?.auth);
  const { currency } = useSelector((state: RootState) => state?.plan);
  const { data: subscriptionInfo, isFetching: subscriptionFeching } =
    useGetUserSubscriptionQuery();
  const { price: currentSubPrice, trialDaysLeft } = subscriptionInfo ?? {};

  const discountedCalculation =
    currentSubPrice - (currentSubPrice * discountPercentage) / 100;
  const discountedPrice = (Math.trunc(discountedCalculation) / 100).toFixed(2);

  const savedCalculation = (
    Math.trunc(currentSubPrice - discountedCalculation) / 100
  ).toFixed(2);
  const currencySymbol = currency ? currencySign?.[currency] : '$';

  const extraTrialDays = 30 - trialDaysLeft;
  const isInTrialPeriod = user?.trial === 1 || trialDaysLeft > 0;
  const trialLeft = isInTrialPeriod
    ? `(${trialDaysLeft} day's trial left)`
    : '';
  const currentSubPriceFixed = (Math.trunc(currentSubPrice) / 100).toFixed(2);
  return (
    <div className="mt-2 w-full lg:max-w-[900px] bg-primary/10 lg:rounded-2xl xs:rounded-lg text-white p-4 px-6 box-border">
      {subscriptionFeching ? (
        <TextLoadingSkeleton />
      ) : (
        <div className="leading-tight lg:mr-5 w-fit">
          <div className="mb-3">
            <span
              dangerouslySetInnerHTML={{
                __html: t('cancelation.discountOffer', {
                  interpolation: { escapeValue: false },
                  userPlan: user?.plan,
                  trialLeft,
                }),
              }}
            />
          </div>

          {isInTrialPeriod ? (
            <div
              dangerouslySetInnerHTML={{
                __html: t('cancelation.inTrialPeriod', {
                  interpolation: { escapeValue: false },
                  extraTrialDays,
                }),
              }}
            />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: t('cancelation.inPaidPeriod', {
                  interpolation: { escapeValue: false },
                  currencySymbol,
                  discountedPrice,
                  currentSubPriceFixed,
                  savedCalculation,
                  discountPercentage,
                }),
              }}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default DiscountPlanPrice;

import { filter, includes, uniqueId } from 'lodash';
import find from 'lodash/find';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FormFieldsSettingProps } from '.';

import RatingStars from '@/components/common/RatingStars';
import EditableText from '@/components/EditableText';
import GroupDeletable from '@/components/GroupDeletable/GroupDeletable';
import LoadingSkeleton from '@/components/products/LoadingSkeleton';
import { RootState } from '@/shared/reduxStore';
import { useGetShopifyTemplateDataQuery } from '@/shared/slices/shopifySlice';
import cn from '@/utils/cn';

interface ImportProductLongBenefitsProps {
  fields: Record<string, any>[];
  onInputChange: ({ value, id, name }: any) => void;
}

function ImportProductReviews({
  fields,
  onInputChange,
}: ImportProductLongBenefitsProps) {
  const { t } = useTranslation();
  const { currentProductId, shopifyHomepageImportTemplate } = useSelector(
    (state: RootState) => state?.products
  );

  const { currentData: shopifyTemplateData } = useGetShopifyTemplateDataQuery(
    {
      productId: currentProductId,
      homepage: shopifyHomepageImportTemplate ? 1 : 0,
    },
    {
      skip: !currentProductId,
    }
  );

  const { reviews } = shopifyTemplateData ?? [];
  const reviewsExist = find(
    fields,
    (field) => field.name.includes('review') && !field.deleted
  );
  const reviewstoDisPlay = filter(
    fields,
    (field) => field.name.includes('review') && !field.deleted
  );

  return (
    <>
      <p
        className={cn(
          'text-center text-[35px] font-bold leading-[56px] tracking-tight',
          !reviewsExist && 'hidden'
        )}
      >
        {t('shopify.whatPeopleSaying')}
      </p>
      <div
        className={cn(
          'grid md:grid-rows-2 xs:grid-rows-4 grid-flow-col gap-2',
          reviewstoDisPlay?.length <= 2 && 'flex'
        )}
      >
        {reviews?.length <= 0 && <LoadingSkeleton />}
        {reviews?.length > 0 &&
          reviews.map(({ author, id }: Record<string, any>, index: number) => {
            const reviewContentField = find(fields, {
              name: `review${index}`,
            }) as FormFieldsSettingProps;
            return (
              <GroupDeletable groupId={id} key={uniqueId()}>
                <div className="md:w-[260px] lg:w-[360px] xs:w-full rounded-[20px] p-[30px] mr-2 text-center box-border shadow-xl shadow-black/5">
                  <div className="w-full mt-2">
                    <EditableText
                      {...reviewContentField}
                      onInputChange={onInputChange}
                    />
                  </div>
                  <div className="mt-2">
                    <RatingStars />
                  </div>
                  <div className="mt-4 flex items-center justify-center sm:mt-2">
                    <p className="text-center text-sm font-bold leading-[32px] tracking-tight">
                      {author}
                    </p>
                  </div>
                </div>
              </GroupDeletable>
            );
          })}
      </div>
    </>
  );
}

export default ImportProductReviews;

import React from 'react';

import Discount from '@/components/Cancellation/Discount';
import Expectations from '@/components/Cancellation/Expectations';
import HowOfften from '@/components/Cancellation/HowOfften';
import Improvements from '@/components/Cancellation/Improvements';
import ImprovementsFollowUp from '@/components/Cancellation/ImprovementsFollowUp';
import Pause from '@/components/Cancellation/Pause';
import Review from '@/components/Cancellation/Review';
import Why from '@/components/Cancellation/Why';

export enum CancellationStepsIndifier {
  WHY_CANCEL = 'whyCancel',
  REVIEW = 'review',
  EXPECTATIONS = 'expectations',
  DISCOUNT_OFFER = 'discount',
  HOW_OFTEN = 'how-often',
  IMPROVEMENTS = 'improvements',
  IMPROVEMENTS_FOLLOW_UP = 'improvements-follow-up',
  PAUSE = 'pause',
}

export const cancellationSteps = ({ t }: any) => [
  {
    id: CancellationStepsIndifier.WHY_CANCEL,
    title: t('cancelation.whyCancel'),
    name: '',
    component: <Why />,
    percengate: 0,
  },
  {
    id: CancellationStepsIndifier.REVIEW,
    title: t('cancelation.review'),
    name: '',
    component: <Review />,
    percengate: 30,
  },
  {
    id: CancellationStepsIndifier.EXPECTATIONS,
    title: t('cancelation.expectations'),
    name: '',
    component: <Expectations />,
    percengate: 30,
  },
  {
    id: CancellationStepsIndifier.DISCOUNT_OFFER,
    title: t('cancelation.discount'),
    name: '',
    component: <Discount />,
    percengate: 30,
  },
  {
    id: CancellationStepsIndifier.HOW_OFTEN,
    title: t('cancelation.howOften'),
    name: '',
    component: <HowOfften />,
    percengate: 30,
  },
  {
    id: CancellationStepsIndifier.IMPROVEMENTS,
    title: t('cancelation.improvements'),
    name: '',
    component: <Improvements />,
    percengate: 30,
  },
  {
    id: CancellationStepsIndifier.IMPROVEMENTS_FOLLOW_UP,
    title: t('cancelation.improvementsFollowUp'),
    name: '',
    component: <ImprovementsFollowUp />,
    percengate: 30,
  },
  {
    id: CancellationStepsIndifier.PAUSE,
    title: t('cancelation.pause'),
    name: '',
    component: <Pause />,
    percengate: 30,
  },
];

import React from 'react';
import ReactDOM from 'react-dom/client';
import 'normalize.css';
import './index.css';
import { Provider } from 'react-redux';
import { Slide, ToastContainer } from 'react-toastify';

import { GoogleAuthProvider } from './components/GoogleAuthContext';
import App from './Routes';
import 'react-toastify/dist/ReactToastify.css';
import '../i18';
import { store } from './shared/reduxStore';

// const developmentSession = 'development-session';
const root = ReactDOM.createRoot(document.getElementById('root'));
// const authSession = localStorage.getItem(developmentSession);
console.log(`Welcome to Productly! Environment ${process.env.VITE_ENV}`);

root.render(
  <Provider store={store}>
    <GoogleAuthProvider>
      <App />
      <ToastContainer
        autoClose={1000}
        position="bottom-center"
        hideProgressBar
        newWindow
        pauseOnHover={false}
        theme="dark"
        transition={Slide}
        limit={3}
      />
    </GoogleAuthProvider>
  </Provider>
);

// const shouldAuthenticate =
//   process.env.VITE_ENV === 'development' ||
//   process.env.VITE_ENV !== 'production' ||
//   !['productly.app', 'www.productly.app', 'beta.productly.app'].includes(
//     document.location.host
//   );
// if (!authSession && shouldAuthenticate) {
//   // eslint-disable-next-line no-alert
//   const password = prompt('Please enter authentication key');
//   if (password !== null && password === 'YYjrNVb5Cx') {
//     localStorage.setItem('development-session', true);
//     window.location.href = '/';
//   }
//   root.render(<h1>Authorization failed!</h1>);
// } else {
//   root.render(
//     <Provider store={store}>
//       <GoogleAuthProvider>
//         <App />
//         <ToastContainer
//           autoClose={1000}
//           position="bottom-center"
//           hideProgressBar
//           newWindow
//           pauseOnHover={false}
//           theme="dark"
//           transition={Slide}
//           limit={3}
//         />
//       </GoogleAuthProvider>
//     </Provider>
//   );
// }

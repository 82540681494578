import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface FormFieldsSettingProps {
  content: FormFieldsSettingProps;
  id: string;
  name: string;
  text: string;
  title?: string;
  image?: string;
  textClass?: string;
  inputType?: string;
  editFieldClass?: string;
  displayBtnClass?: string;
  editable?: boolean;
  deleted?: number;
}

const initialState: FormFieldsSettingProps[] = [];

const shopifyTemplateFieldsSlice = createSlice({
  name: 'cancellation',
  initialState,
  reducers: {
    setShopifyTemplateFields: (
      _,
      { payload }: PayloadAction<FormFieldsSettingProps[]>
    ) => [...payload],
  },
});

export const { setShopifyTemplateFields } = shopifyTemplateFieldsSlice.actions;

export default shopifyTemplateFieldsSlice.reducer;

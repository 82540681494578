/* eslint-disable react/forbid-dom-props */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SiShopify } from 'react-icons/si';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ConnectToShopifyButton from '../Buttons/ConnectToShopifyButton';
import Button from '../common/Button';
import PlusIcon from '../common/svg/PlusIcon';

import { cn } from '@/lib/utils';
import { FeatureFlags, displayFeature } from '@/shared/configs/featureFlags';
import { setApplicationState } from '@/shared/reducers/applicationReducer';
import { setFromScratch } from '@/shared/reducers/fromScratchReducer';
import { setProductsState } from '@/shared/reducers/productsReducer';
import { Plan } from '@/shared/types/user';

interface ProductsInfoProps {
  user: any;
  stock: number;
  generatedProducts: number;
  regeneratedProducts: number;
  setShowAddProductForm: (show: boolean) => void;
  shopifyConnectWizardDisplay?: boolean;
}

function ProductInfo({
  user,
  stock,
  generatedProducts,
  regeneratedProducts,
  setShowAddProductForm,
  shopifyConnectWizardDisplay,
}: ProductsInfoProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('translation');
  const producstLimits = {
    free: 1,
    Starter: 3,
    Standard: 10,
    Pro: 50,
    Lifetime: 9999,
  };

  const maxProductsForPlan = (plan: Plan) => stock ?? producstLimits[plan];

  const regenerationLimits = {
    free: 0,
    Starter: 30,
    Standard: 200,
    Pro: 500,
    Lifetime: 9999,
  };

  const maxRegenerationsForPlan = (plan: Plan) => regenerationLimits[plan] || 0;
  const progressRegBarWidth = (current: number, max: number) =>
    (current / max) * 100;

  const progressBarWidth = () => {
    if (user && user.plan) {
      const maxProducts = maxProductsForPlan(user.plan);

      const progress = ((generatedProducts ?? 0) / maxProducts) * 100;

      return progress > 100 ? 100 : progress;
    }
    return 0;
  };

  const importToShopifyFeature = displayFeature({
    featureFlag: FeatureFlags?.PRODLY_336_IMPORT_TO_SHOPIFY_20240612,
  });

  const isUserPlanStillValid = user?.expiration !== true;
  const isGeneratedNotLimit = generatedProducts < stock;

  const shopifyImportDisplay =
    importToShopifyFeature &&
    !user?.shopify &&
    user?.email === 'vietnguyen010@gmail.com';

  return (
    <div className="flex flex-col items-center text-white mt-[30px]">
      <div className="hidden lg:flex">
        {shopifyConnectWizardDisplay && (
          <ConnectToShopifyButton
            className="mb-1"
            text={t('shopify.connectYourShopify')}
          />
        )}
      </div>

      {shopifyImportDisplay && (
        <Button
          text={t('shopify.connectYourShopify')}
          onClick={() => {
            dispatch(setProductsState({ shopifyConnectModal: true }));
            dispatch(setFromScratch({ active: false }));
            dispatch(setApplicationState({ active: false }));
          }}
          icon={<SiShopify className="ml-2 text-lg" />}
          className={cn(
            'w-full flex items-center justify-center font-semibold rounded-sm bg-shopifyDark cursor-pointer mb-[10px] py-[12px] px-10 border-none text-white text-[15px]',
            user?.shopify && 'hidden'
          )}
        />
      )}

      {isGeneratedNotLimit && isUserPlanStillValid ? (
        <button
          type="button"
          onClick={() => {
            setShowAddProductForm(true);
          }}
          className="w-full flex items-center justify-center font-semibold rounded-sm bg-white cursor-pointer mb-[10px] py-[12px] px-10 border-none text-black text-[15px]"
        >
          {t('memberArea.addProduct')}
          <PlusIcon className="ml-2" />
        </button>
      ) : (
        <div className="flex flex-col w-full">
          <Button
            text={t('common.upgrade')}
            className="w-full py-4 bg-primary rounded-lg text-white outline-none border-0 mb-3"
            onClick={() => navigate('/upgrade')}
          />
        </div>
      )}
      <div className="relative flex flex-col items-start w-full">
        <div className="relative w-full my-0">
          <div className="w-full">
            <p className="mt-[5px] text-xs ">
              {t('common.products')}: {generatedProducts}/
              {stock ?? maxProductsForPlan(user?.plan)}
            </p>

            <div className="w-full h-4 rounded-sm -mt-[10px] bg-[#ffffff26]">
              <div
                className="rounded-sm h-full bg-[#ffffff40]"
                style={{ width: `${progressBarWidth()}%` }}
              />
            </div>
            <p className="text-xs mt-[4px]">
              {t('common.regenerations')}: {regeneratedProducts}/
              {maxRegenerationsForPlan(user?.plan)}
            </p>
            <div className="w-full h-4 rounded-sm -mt-[10px] bg-[#ffffff26]">
              <div
                className="rounded-sm h-full bg-[#ffffff40]"
                style={{
                  width: `${progressRegBarWidth(
                    regeneratedProducts,
                    maxRegenerationsForPlan(user?.plan)
                  )}%`,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductInfo;

import { useTranslation } from 'react-i18next';

import { Plan } from '../types/user';

export type SubService = {
  title: string;
  key: string;
};
export type Service = {
  key: string;
  keyV3?: string;
  title?: string;
  blurredFor: string[];
  subSrvice: SubService[];
  withH2?: boolean;
  withoutRegenerate?: boolean;
};
export type serviceTitleLanguage = {
  fr: string;
  en: string;
};

export type ServiceV3 = {
  serviceKey: string;
  title?: serviceTitleLanguage[];
  blurredFor: string[];
  generateFromScratch?: boolean;
  expandAll?: boolean;
};

export const baseServices = [
  {
    name: 'Description Produit',
    icon: 'description',
    key: 'description',
    api_key: 'description',
    locked: false,
  },
  {
    name: 'Homepage',
    icon: 'web',
    key: 'homepage',
    api_key: 'homepage',
    locked: true,
  },
  {
    name: 'Bénéfices Produit',
    icon: 'storefront',
    key: 'benefits',
    api_key: 'benefices',
    locked: false,
  },
  {
    name: 'Creatives',
    icon: 'emoji_objects',
    key: 'creatives',
    api_key: 'generate_creatives',
    locked: false,
  },
  {
    name: 'Objections achat',
    icon: 'storefront',
    key: 'objections',
    api_key: 'generate_objections',
    locked: false,
  },
  {
    name: 'Ad copies',
    icon: 'public',
    key: 'pub',
    api_key: 'adcopies',
    locked: true,
  },
  {
    name: 'Avis clients',
    icon: 'sell',
    key: 'reviews',
    locked: true,
  },
  {
    name: 'Email marketing',
    icon: 'mark_email_read',
    key: 'email',
    api_key: 'email1',
    locked: true,
  },

  {
    name: 'SMS Marketing',
    icon: 'sms',
    key: 'sms',
    api_key: 'sms1',
    locked: true,
  },
  {
    name: 'Upsells & Bundles',
    icon: 'payments',
    key: 'pricing',
    api_key: 'pricing',
    locked: true,
  },
  {
    name: 'Brief Influenceurs',
    icon: 'diversity_3',
    key: 'influ',
    api_key: 'influ',
    locked: true,
  },
  {
    name: 'Redaction SAV',
    icon: 'live_help',
    key: 'sav',
    api_key: 'sav5',
    locked: true,
  },
  {
    name: 'Legal Policies',
    icon: 'encrypted_add_circle',
    key: 'ecomApplication',
    api_key: 'legal',
    locked: true,
  },

  // { name: 'Admin', icon: 'admin_panel_settings', key: 'admin', locked: true },
  // { name: 'SEO',icon: 'travel_explore', key: 'seo', locked: true },
];
const free = ['description'];
const starter = [
  ...free,
  'benefits',
  'creatives',
  'objections',
  'email',
  'homepage',
  'sms',
  'pricing',
  'pub',
  'influ',
  'sav',
];
const standard = [...starter, 'reviews'];
const pro = [...standard, 'ecomApplication'];

export const getServices = (plan: Plan) => {
  switch (plan) {
    case 'free':
      return baseServices.map((service) =>
        service.key === 'description'
          ? { ...service, locked: false }
          : { ...service, locked: true }
      );
    case 'Starter':
      return baseServices.map((service) =>
        starter.includes(service.key)
          ? { ...service, locked: false }
          : { ...service, locked: true }
      );
    case 'Standard':
      return baseServices.map((service) =>
        standard.includes(service.key)
          ? { ...service, locked: false }
          : { ...service, locked: true }
      );
    case 'Pro':
    case 'Lifetime':
      return baseServices.map((service) =>
        pro.includes(service.key)
          ? { ...service, locked: false }
          : { ...service, locked: true }
      );
    default:
      return baseServices;
  }
};

export const servicesMap: Service[] = [
  {
    key: 'description',
    blurredFor: ['free'],
    subSrvice: [
      {
        title: 'Description produit neuro-marketing',
        key: 'description',
      },
      { title: 'Description Produit', key: 'description2' },
    ],
  },
  {
    key: 'homepage',
    blurredFor: ['free', 'Starter', 'Standard'],
    subSrvice: [],
  },
  {
    key: 'benefits',
    keyV3: 'generate_benefits',
    blurredFor: ['free', 'Starter'],
    subSrvice: [
      { title: 'Bénéfices Produit', key: 'benefices' },
      { title: "Objections à l'achat", key: 'objections' },
    ],
    withoutRegenerate: true,
  },
  {
    key: 'objections',
    keyV3: 'generate_objections',
    blurredFor: ['free', 'Starter'],
    subSrvice: [
      { title: 'Objections achat & Objection levée', key: 'objections' },
    ],
    withoutRegenerate: true,
  },
  {
    key: 'creatives',
    keyV3: 'generate_creatives',
    blurredFor: ['free', 'Starter'],
    subSrvice: [{ title: 'Creatives', key: 'creatives' }],
    withoutRegenerate: true,
  },
  {
    key: 'email',
    keyV3: 'generate_emails',
    title: 'E-mails marketing',
    blurredFor: ['free', 'Starter', 'Standard'],
    subSrvice: [
      { title: 'Inscription à la newsletter', key: 'email1' },
      { title: 'Relance après visite', key: 'email2' },
      { title: 'Post achat', key: 'email3' },
      { title: 'Relance post achat', key: 'email4' },
      { title: 'Panier abandonné', key: 'email5' },
      { title: 'Panier abandonné avec réduction', key: 'email6' },
      { title: 'Bénéfices produit', key: 'email7' },
      { title: 'Contre-objections', key: 'email8' },
    ],
    withH2: true,
  },
  {
    key: 'sms',
    blurredFor: ['free', 'Starter', 'Standard'],
    subSrvice: [
      { title: 'Inscription', key: 'sms1' },
      { title: 'Panier abandonné', key: 'sms2' },
      { title: 'Panier abandonné avec réduction', key: 'sms3' },
      { title: 'Relance / offre', key: 'sms4' },
    ],
  },
  {
    key: 'pricing',
    blurredFor: ['free', 'Starter', 'Standard'],
    subSrvice: [{ title: 'Upsells & Bundles', key: 'pricing' }],
  },
  {
    key: 'influ',
    blurredFor: ['free', 'Starter', 'Standard'],
    subSrvice: [
      { title: 'Brief influenceur', key: 'influ' },
      { title: 'Outreach influ', key: 'outreach' },
      { title: 'Promotion contre rémunération', key: 'remunerationinf' },
    ],
  },
  {
    key: 'pub',
    blurredFor: ['free', 'Starter'],
    subSrvice: [{ title: 'Publicités', key: 'adcopies' }],
  },
  {
    key: 'sav',
    blurredFor: ['free', 'Starter', 'Standard', 'adcopies'],
    subSrvice: [
      { title: 'Questions garantie', key: 'sav5' },
      { title: 'Client non satisfait', key: 'sav6' },
      { title: 'Annulation commande', key: 'sav7' },
      { title: 'Produit cassé', key: 'sav8' },
      {
        title: 'Status du colis : En transit / En traitement / Retardé',
        key: 'sav9',
      },
      { title: 'Status du colis : Envoyé - Pas encore de suivi', key: 'sav10' },
      { title: 'Livraison retardé', key: 'sav11' },
      { title: 'Colis arrivé dans le pays', key: 'sav12' },
      { title: "Colis renvoyé à l'expéditeur", key: 'sav13' },
    ],
  },
  {
    key: 'ecomApplication',
    blurredFor: ['free'],
    subSrvice: [{ title: 'Legal Policies', key: 'ecomApplication' }],
  },
];

export const servicesMapV3: ServiceV3[] = [
  {
    serviceKey: 'generate_benefits',
    title: [{ fr: 'Bénéfices Produit', en: 'Product Benefits' }],
    blurredFor: ['free', 'Starter'],
    expandAll: true,
  },
  {
    serviceKey: 'generate_objections',
    title: [
      {
        fr: 'Objections achat & Objection levée',
        en: 'Purchase Objections & Objection Removed',
      },
    ],
    blurredFor: ['free', 'Starter'],
    expandAll: true,
  },
  {
    serviceKey: 'generate_email',
    title: [{ fr: 'E-mails marketing', en: 'Marketing emails' }],
    blurredFor: ['free', 'Starter', 'Standard'],
    generateFromScratch: true,
  },
  {
    serviceKey: 'generate_creatives',
    title: [{ fr: 'Creative', en: 'Creative' }],
    blurredFor: ['free', 'Starter'],
    expandAll: true,
  },
];

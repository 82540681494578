import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdDelete } from 'react-icons/md';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Button from '../common/Button';

import { setShopifyTemplateFields } from '@/shared/reducers/shopifyTemplateFieldsReducer';
import { RootState } from '@/shared/rootReducers';
import { useDeleteShopifyTemplatePartsMutation } from '@/shared/slices/shopifySlice';
import cn from '@/utils/cn';

interface GroupDeletableProps {
  children: React.JSX.Element;
  groupId: any;
}
function GroupDeletable({ children, groupId }: GroupDeletableProps) {
  const dispatch = useDispatch();
  const productInfoFields = useSelector(
    (state: RootState) => state?.shopifyTemplateFields
  );

  const { currentProductId, shopifyHomepageImportTemplate } = useSelector(
    (state: RootState) => state?.products
  );
  const { t } = useTranslation();

  const [modalDisplay, setModalDisplay] = useState<boolean>(false);
  // const [validDisplay, setValidDisplay] = useState<boolean>(true);

  const [shopifyDeleteTemplateParts] = useDeleteShopifyTemplatePartsMutation();

  const deleteTemplate = async () => {
    if (!currentProductId || !groupId) return;
    setModalDisplay(false);
    dispatch(
      setShopifyTemplateFields(
        productInfoFields.map((field) => {
          const copyOfField = { ...field };
          const groupIdConvert = Array.isArray(groupId) ? groupId : [groupId];
          if (groupIdConvert.includes(field.id)) {
            copyOfField.deleted = 1;
          }
          return copyOfField;
        })
      )
    );

    const response: any = await shopifyDeleteTemplateParts({
      homepage: shopifyHomepageImportTemplate ? 1 : 0,
      ids: groupId,
    });
    if (response.data?.success) {
      toast.success(t('memberArea.successDeleteSection'));
    } else {
      toast.error(t('memberArea.deleteSectionFailed'));
    }
  };

  /**
   * Hide the template section when:
   *  - Fields setting based on the group_id have the deleted flag === 1
   *  - Meaning of field.deleted === -1 is make sure always return an empty array for not related fields.
   *    If not, deleted one field can cause all fields disappeared
   */
  const invalidDisplay =
    productInfoFields.filter((field) => {
      const groupIdConvert = Array.isArray(groupId) ? groupId : [groupId];
      if (groupIdConvert.includes(field.id)) {
        return field.deleted === 1;
      }
      return field.deleted === -1;
    })?.length > 0;

  return (
    <div
      className={cn(
        'relative hover:border hover:border-dashed hover:border-zinc-400 [&>span]:hover:flex',
        invalidDisplay && 'hidden'
      )}
    >
      {children}
      <span className="w-[24px] absolute top-0 right-0 hidden [&>button]:hover:flex">
        <Button
          className="border-none shadow-none flex bg-transparent h-fit text-lg"
          onClick={() => setModalDisplay(true)}
        >
          <MdDelete className="text-lg text-primary w-[24px]" />
        </Button>
      </span>
      <Modal
        className="modal-container"
        isOpen={modalDisplay}
        overlayClassName="modal-overlay"
      >
        <div className="max-w-md bg-white rounded-lg md:min-w-full p-7 ">
          <div>{t('memberArea.confirmDeleteSection')}</div>
          <div className="flex justify-center mt-4">
            <Button
              className="p-2 mr-2 text-white border-none rounded-lg bg-primary"
              text={t('common.yes')}
              type="button"
              onClick={deleteTemplate}
            />
            <Button
              className="p-2 mr-2 text-white border-none rounded-lg bg-primary"
              text={t('common.no')}
              type="button"
              onClick={() => setModalDisplay(false)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default GroupDeletable;

import _find from 'lodash/find';
import React, { EventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Label } from '../common/Label';
import { RadioGroup, RadioGroupItem } from '../common/shadcn-ui/RadioGroup';
import { Textarea } from '../common/shadcn-ui/Textarea';

import { cn } from '@/lib/utils';
import { setCancellationState } from '@/shared/reducers/cancellationReducer';
import { RootState } from '@/shared/reduxStore';

type RadioSettingProps = {
  id: string;
  label: string;
  detail?: boolean;
};

function Why() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showDetail, setShowDetail] = useState(false);
  const [detailError, setDetailError] = useState('');
  const { whyOption = '', whyDetail = '' } = useSelector(
    (state: RootState) => state.cancellation
  );
  const radiosSettings: RadioSettingProps[] = [
    {
      id: 'time',
      label: t('cancelation.lessTime'),
    },
    {
      id: 'expectation',
      label: t('cancelation.unexpected'),
      detail: true,
    },
    {
      id: 'no-longer-needed',
      label: t('cancelation.noNeeded'),
    },
    {
      id: 'missing-feature',
      label: t('cancelation.missingFeature'),
      detail: true,
    },
    {
      id: 'technical-issues',
      label: t('cancelation.technicalIssues'),
      detail: true,
    },
    {
      id: 'other',
      label: t('cancelation.other'),
      detail: true,
    },
  ];

  const handleDetailChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e?.currentTarget ?? {};
    if (!value || value?.length < 25) {
      setDetailError(t('cancelation.detailError'));
      return;
    }
    dispatch(
      setCancellationState({ whyDetail: value, stepperNextBtnDisable: false })
    );
    setDetailError('');
  };

  const handleOptionChange = (value: string) => {
    const detailRequired =
      _find(radiosSettings, { id: value })?.detail ?? false;
    dispatch(
      setCancellationState({
        whyOption: value,
        whyDetail: '',
        stepperNextBtnDisable: detailRequired,
      })
    );
  };

  useEffect(() => {
    if (whyDetail === '') return;
    setShowDetail(true);
  }, [whyDetail]);

  return (
    <RadioGroup
      defaultValue={whyOption}
      className="text-white"
      onValueChange={handleOptionChange}
    >
      {radiosSettings.map(({ id, label, detail = false }) => (
        <div className="flex items-center space-x-2">
          <RadioGroupItem
            value={id}
            id={id}
            onClick={() => setShowDetail(detail)}
          />
          <Label htmlFor={id}>{label}</Label>
        </div>
      ))}
      {showDetail && (
        <div className="space-y-2 mt-5">
          <h4>{t('cancelation.moreDetail')}</h4>
          <Textarea
            className={cn(
              `text-white border-primary border-solid outline-none focus-visible:ring-0 placeholder:text-white/40 box-border min-h-[100px]`,
              detailError !== '' && 'border-red-500'
            )}
            placeholder={t('cancelation.min25Chars')}
            onChange={handleDetailChange}
            defaultValue={whyDetail}
          />
          {detailError && (
            <div className="text-red-500 text-sm">{detailError}</div>
          )}
        </div>
      )}
    </RadioGroup>
  );
}

export default Why;

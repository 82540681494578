/* eslint-disable react/no-danger */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IoCloseCircleSharp } from 'react-icons/io5';
import Modal from 'react-modal';

import Button from '../common/Button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../common/Card';

import { cn } from '@/lib/utils';

interface ConfirmationModalProps {
  title: string;
  modalClass?: string;
  open: boolean;
  description?: string;
  children: React.JSX.Element;
  onDecline: () => void;
  onConfirm: () => void;
  confirmFocus?: boolean;
  declineFocus?: boolean;
}

function ConfirmationModal({
  title,
  modalClass = '',
  open = false,
  description,
  children,
  onDecline,
  onConfirm,
  confirmFocus = false,
  declineFocus = false,
}: ConfirmationModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      closeTimeoutMS={500}
      className={cn(
        `modal-container relative max-w-350 outline-none`,
        modalClass
      )}
      isOpen={open ?? false}
      overlayClassName="modal-overlay"
    >
      <Button
        text=""
        className="bg-transparent border-0 outline-none w-full !justify-end absolute top-3"
        icon={<IoCloseCircleSharp className="text-3xl" />}
        onClick={onDecline}
      />
      <Card>
        <CardHeader>
          <CardTitle className="m-0">{title}</CardTitle>
          {description && (
            <CardDescription className="text-xs">{description}</CardDescription>
          )}
        </CardHeader>
        <CardContent>{children}</CardContent>
        <CardFooter className="flex justify-end mt-3">
          <Button
            onClick={onDecline}
            className={cn(
              `border-none bg-zinc-200 px-4 text-sm rounded-sm py-2 mr-2 transition-all`,
              declineFocus && 'bg-primary text-white'
            )}
          >
            {t('common.no')}
          </Button>
          <Button
            onClick={onConfirm}
            className={cn(
              `border-none bg-zinc-200 px-4 text-sm rounded-sm py-2 mr-2 transition-all`,
              confirmFocus && 'bg-primary text-white'
            )}
          >
            {t('common.yes')}
          </Button>
        </CardFooter>
      </Card>
    </Modal>
  );
}

export default ConfirmationModal;

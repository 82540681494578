import find from 'lodash/find';
import React from 'react';

import { FormFieldsSettingProps } from '.';

import EditableText from '@/components/EditableText';
import GroupDeletable from '@/components/GroupDeletable/GroupDeletable';
import cn from '@/utils/cn';

interface ImportProductFaqProps {
  fields: Record<string, any>[];
  onInputChange: ({ value, id, name }: any) => void;
}

function ImportBanner({ fields, onInputChange }: ImportProductFaqProps) {
  const bannerField = find(fields, {
    name: `bannerBenefit`,
  }) as FormFieldsSettingProps;
  return (
    <div
      className={cn(
        `w-full flex items-center justify-center h-[300px] bg-[url('/public/shopify/default-banner.svg')] bg-cover bg-center`,
        bannerField?.deleted && 'h-[0px]'
      )}
    >
      <h3 className="bg-white mx-28 p-2 rounded-lg">
        <GroupDeletable groupId={bannerField?.id}>
          <EditableText {...bannerField} onInputChange={onInputChange} />
        </GroupDeletable>
      </h3>
    </div>
  );
}

export default ImportBanner;

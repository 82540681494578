/* eslint-disable react/no-danger */
/* eslint-disable react-hooks/exhaustive-deps */
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import { z } from 'zod';

import Button from '@/components/common/Button';
import FormFieldInput from '@/components/common/FormFields/Input';
import { Form } from '@/components/common/shadcn-ui/Form';
import Stepper, { StepDataProps } from '@/components/common/Stepper';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/common/Tabs';
import ProductAppLayout from '@/components/products/Layout';
import Video from '@/components/Video';
import { instructionSteps } from '@/shared/configs/shopifyConnectSteppers';
import { useLazyGetGenerateNewTokenQuery } from '@/shared/slices/authSlice';
import { useShopifyConnectMutation } from '@/shared/slices/shopifySlice';

function ShopifyConnect() {
  const { t } = useTranslation();
  const [
    shopifyConnect,
    { data: shopifyConnectData, isLoading: shopifyConnectLoading },
  ] = useShopifyConnectMutation();
  const { success: connectSuccess, message: connectMessage } =
    shopifyConnectData ?? {};
  const [generateNewToken] = useLazyGetGenerateNewTokenQuery();
  const stepperData = instructionSteps({ t });
  const [currentStep, setCurrentStep] = useState<StepDataProps>(stepperData[0]);

  const formSchema = z.object({
    shopifyUrl: z.string().min(2, {
      message: t('shopify.shopUrlEmpty'),
    }),
    shopifyCode: z.string().min(2, {
      message: t('shopify.shopCodeEmpty'),
    }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      shopifyUrl: '',
      shopifyCode: '',
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    shopifyConnect(values);
  }

  useEffect(() => {
    if (connectSuccess) {
      generateNewToken(undefined, false);
      return;
    }
    if (!connectMessage) return;
    form.setError('shopifyUrl', {
      type: 'error',
      message: t(`shopify.${connectMessage}`),
    });
  }, [connectMessage, connectSuccess]);

  return (
    <ProductAppLayout>
      <Form {...form}>
        <div className="w-full bg-transparent text-white max-w-[800px] p-0 m-0">
          <div className="mb-6">
            <div className="m-0 font-bold">
              <h2 className="m-0">{t('shopify.connectYourShopify')}</h2>
            </div>
          </div>
          {!shopifyConnectLoading && connectSuccess ? (
            <div className="text-primary border border-solid p-10 space-y-5 border-shopify bg-defaultBlack rounded-lg">
              <div className="flex w-full justify-center">
                <IoCheckmarkCircleSharp className="text-5xl mt-[2px] mr-2 text-shopify" />
              </div>
              <div className="flex w-full justify-center text-center">
                <h3 className="m-0 mb-2 text-white font-semibold">
                  <p
                    className="[&>_a]:!text-primary [&>_a]::hover:text-opacity-90"
                    dangerouslySetInnerHTML={{
                      __html: t('shopify.shopifyConnectSuccess', {
                        interpolation: { escapeValue: false },
                      }),
                    }}
                  />
                </h3>
              </div>
            </div>
          ) : (
            <>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-8"
              >
                <FormFieldInput
                  control={form.control}
                  name="shopifyUrl"
                  label={t('shopify.shopUrlField')}
                  placeholder={t('shopify.shopUrlFieldPlaceholder')}
                  inputClass="text-sm indent-3 py-3 h-auto placeholder:text-white/30"
                />
                <FormFieldInput
                  control={form.control}
                  name="shopifyCode"
                  label={t('shopify.shopCodeField')}
                  placeholder={t('shopify.shopCodeFieldPlaceholder')}
                  inputClass="text-sm indent-3 py-3 h-auto placeholder:text-white/30"
                />
                <div className="flex justify-end mt-5">
                  <Button
                    type="submit"
                    loadingText={t('shopify.connecting')}
                    loading={shopifyConnectLoading}
                    className="border-none bg-primary px-4 text-sm rounded-sm py-2 hover:bg-shopify text-white transition-all disabled:bg-zinc-200 disabled:text-zinc-500 disabled:cursor-not-allowed"
                  >
                    {t('shopify.shopifyConnectButton')}
                  </Button>
                </div>
              </form>
              <div className="xl:max-w-[800px] xl:w-[800px] xs:w-full bg-defaultBlack border border-solid border-primary/50 rounded-lg mt-10 p-5 box-border">
                <div className="mb-6">
                  <div className="m-0 font-bold">
                    <h2 className="m-0">{t('shopify.wizardHeader')}</h2>
                  </div>
                  <div className="text-sm text-zinc-400 mt-3">
                    {t('shopify.wizardSubtitle')}
                  </div>
                </div>
                <Tabs
                  defaultValue="instruction-video"
                  className="flex flex-col items-center w-full"
                >
                  <TabsList>
                    <TabsTrigger value="instruction-video">
                      {t('shopify.videoInstructions')}
                    </TabsTrigger>
                    <TabsTrigger value="instruction-text">
                      {t('shopify.textInstructions')}
                    </TabsTrigger>
                  </TabsList>

                  <TabsContent
                    className="text-white w-full"
                    value="instruction-video"
                  >
                    <Video
                      animationClass="w-full h-full sm:h-[200px] md:h-[400px] !p-0"
                      showHeader={false}
                      video="shopifyInstruction"
                    />
                  </TabsContent>
                  <TabsContent
                    className="text-white w-full"
                    value="instruction-text"
                  >
                    <h3 className="text-primary">{currentStep?.name}</h3>
                    <Stepper
                      showProgress={false}
                      showStepHeader={false}
                      showStepMark={false}
                      stepData={stepperData}
                      currentStep={currentStep}
                      setCurrentStep={setCurrentStep}
                      lastStepAction={() => {}}
                    />
                  </TabsContent>
                </Tabs>
              </div>
            </>
          )}
        </div>
      </Form>
    </ProductAppLayout>
  );
}

export default ShopifyConnect;

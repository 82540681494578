/* eslint-disable react-hooks/exhaustive-deps */
import _find from 'lodash/find';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Textarea } from '../common/shadcn-ui/Textarea';

import { cn } from '@/lib/utils';
import { setCancellationState } from '@/shared/reducers/cancellationReducer';
import { RootState } from '@/shared/reduxStore';

function Expectations() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [detailError, setDetailError] = useState('');
  const { expectationsDetail = '' } = useSelector(
    (state: RootState) => state.cancellation
  );

  const handleDetailChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e?.currentTarget ?? {};
    if (!value || value?.length < 25) {
      setDetailError(`Please add more character...`);
      dispatch(
        setCancellationState({
          stepperNextBtnDisable: true,
        })
      );
      return;
    }
    dispatch(
      setCancellationState({
        expectationsDetail: value,
        stepperNextBtnDisable: false,
      })
    );
    setDetailError('');
  };

  useEffect(() => {
    dispatch(
      setCancellationState({
        stepperNextBtnDisable: expectationsDetail === '',
      })
    );
  }, [expectationsDetail]);

  return (
    <div>
      <div className="space-y-2 mt-10 text-white">
        <h4>{t('cancelation.toDoBetter')}</h4>
        <Textarea
          className={cn(
            `text-white border-primary border-solid outline-none focus-visible:ring-0 placeholder:text-white/40 box-border min-h-[100px]`,
            detailError !== '' && 'border-red-500'
          )}
          placeholder={t('cancelation.min25Chars')}
          onChange={handleDetailChange}
          defaultValue={expectationsDetail}
        />
        {detailError && (
          <div className="text-red-500 text-sm">{detailError}</div>
        )}
      </div>
    </div>
  );
}

export default Expectations;

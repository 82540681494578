export type Plan = 'free' | 'Starter' | 'Standard' | 'Pro' | 'Lifetime';
export enum PlansLevel {
  free = 0,
  Starter = 1,
  Standard = 2,
  Pro = 3,
  Lifetime = 4,
}

export enum SubscriptionStatus {
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  PAST_DUE = 'past_due',
  CANCELED = 'canceled',
  UNPAID = 'unpaid',
}

import React, { useEffect, useState } from 'react';
import './css/try.css';
import { useTranslation } from 'react-i18next';

import Animation from './common/Animation';
import YoutubeEmbed from './common/YoutubeEmbed';

import { cn } from '@/lib/utils';

interface PresentationVideoProps {
  showHeader?: boolean;
  animationClass?: string;
  video?: 'presentation' | 'shopifyInstruction' | 'legalInstruction';
}

const VideoConfigs = {
  presentation: {
    en: 'Kh2T5x1U0eg',
    fr: 'eVolTjsi7Ts',
  },
  shopifyInstruction: {
    en: 'gTQd1aDT1N0',
    fr: 'gTQd1aDT1N0',
  },
  legalInstruction: {
    en: 'W6Oe5WWAjvk',
    fr: 'W6Oe5WWAjvk',
  },
};

function Video({
  showHeader = true,
  animationClass = '',
  video = 'presentation',
}: PresentationVideoProps) {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'home' });
  const [videoId, setVideoId] = useState(VideoConfigs[video].en);

  useEffect(() => {
    if (i18n?.language === 'en') {
      setVideoId(VideoConfigs[video].en);
      return;
    }
    setVideoId(VideoConfigs[video].fr);
  }, [i18n?.language, video]);

  return (
    <Animation
      className={cn(
        'z-[2] relative items-center p-[200px_200px_100px_200px] text-white justify-center flex flex-col xs:p-[200px_20px_100px] lg:p-[200px_200px_100px_200px]',
        animationClass
      )}
      id="presentation-vid"
    >
      <>
        {showHeader && (
          <h1 className="text-center xs:text-[1.6rem] sm:text-[1.8] md:text-[2rem]">
            {t('videoTitle1')}{' '}
            <span className="text-[#a238d5] inline-block relative">
              {t('videoTitle2')}
            </span>{' '}
            {t('videoTitle3')}
          </h1>
        )}
        <YoutubeEmbed videoId={videoId} />
      </>
    </Animation>
  );
}

export default Video;

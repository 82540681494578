import { getUnixTime } from 'date-fns';
import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { removeCredential } from '@/shared/reducers/authReducer';

function PrivateRoute({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem('productly');

  try {
    const user = jwtDecode(token);
    if (user?.exp < getUnixTime(new Date())) {
      dispatch(removeCredential());
      navigate('/login');
      return <p />;
    }
    return children;
  } catch (error) {
    return (
      <Navigate
        to={{
          pathname: '/login',
          search: location.search,
        }}
        state={{ previousPath: location.pathname }}
      />
    );
  }
}

export default PrivateRoute;

import React from 'react';
import { IoCloseCircleSharp } from 'react-icons/io5';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import ShopifyConnectSimple from '../app/shopify/connect/Simple';
import Button from '../common/Button';

import { setProductsState } from '@/shared/reducers/productsReducer';
import { RootState } from '@/shared/reduxStore';

function ShopifyConnectModal() {
  const dispatch = useDispatch();
  const { shopifyConnectModal } = useSelector(
    (state: RootState) => state?.products
  );
  return (
    <Modal
      closeTimeoutMS={500}
      className="modal-container relative"
      isOpen={shopifyConnectModal ?? false}
      overlayClassName="modal-overlay"
    >
      <Button
        text=""
        className="bg-transparent border-0 outline-none w-full !justify-end absolute top-3"
        icon={<IoCloseCircleSharp className="text-3xl" />}
        onClick={() =>
          dispatch(setProductsState({ shopifyConnectModal: false }))
        }
      />
      <ShopifyConnectSimple />
    </Modal>
  );
}

export default ShopifyConnectModal;

/* eslint-disable react/no-danger */
import Animation from 'components/common/Animation';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import { LuLoader2 } from 'react-icons/lu';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import Header from '../components/Header';

import ShopifyConnectSimple from '@/components/app/shopify/connect/Simple';
import { RootState } from '@/shared/reduxStore';

function ShopifyConnectPage() {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state?.auth);
  const [searchParams] = useSearchParams();
  const shopName = searchParams.get('shop');

  useEffect(() => {
    if (!shopName || user?.shopify) return;
    window.location.href = `${process.env.VITE_APP_API_URL}/shopify/auth?shop=${shopName}&token=${localStorage.getItem('productly')}`;
  }, [shopName, user]);

  return (
    <div>
      <div className="absolute w-full h-full pointer-events-none bg-change-password-gradient-background" />
      <Header />
      <Animation className="form-container">
        <>
          {shopName && !user?.shopify && (
            <p className="text-white flex items-center">
              <LuLoader2 className="animate-spin mr-2 text-2xl" />
              You will be redirect soon to Shopify for authentication...
            </p>
          )}
          {!shopName && !user?.shopify && <ShopifyConnectSimple />}
          {!!user?.shopify && (
            <div className="text-primary border border-solid p-10 space-y-5 border-shopify bg-defaultBlack rounded-lg">
              <div className="flex w-full justify-center">
                <IoCheckmarkCircleSharp className="text-5xl mt-[2px] mr-2 text-shopify" />
              </div>
              <div className="flex w-full justify-center text-center">
                <h3 className="m-0 mb-2 text-white font-semibold">
                  <p
                    className="[&>_a]:!text-primary [&>_a]::hover:text-opacity-90"
                    dangerouslySetInnerHTML={{
                      __html: t('shopify.shopifyConnectSuccess', {
                        interpolation: { escapeValue: false },
                      }),
                    }}
                  />
                </h3>
              </div>
            </div>
          )}
        </>
      </Animation>
    </div>
  );
}

export default ShopifyConnectPage;

import React from 'react';

interface YoutubeEmbedProps {
  videoId: string;
  iframeProps?: Record<string, any>;
}

function YoutubeEmbed({ videoId, iframeProps = {} }: YoutubeEmbedProps) {
  return (
    <div className="overflow-hidden relative xs:h-[308px] sm:h-[520px] lg:h-[550px] 2xl:h-[590px] w-full max-w-[1024px]">
      <iframe
        className="left-0 top-0 h-full w-full absolute"
        src={`https://www.youtube.com/embed/${videoId}?rel=0`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
        {...iframeProps}
      />
    </div>
  );
}

export default YoutubeEmbed;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiUser } from 'react-icons/fi';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { MdAccountCircle } from 'react-icons/md';
import { RiAiGenerate, RiShutDownLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/common/DropdownMenu';
import { cn } from '@/lib/utils';
import { removeCredential } from '@/shared/reducers/authReducer';
import { RootState } from '@/shared/reduxStore';

interface memberMenuButtonProps {
  className?: string;
}

function MemberMenuButton({ className }: memberMenuButtonProps) {
  // If the current location is "/app", don't render the button or render something else
  const { t } = useTranslation('translation');
  const { user } = useSelector((state: RootState) => state?.auth);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(removeCredential());
    navigate('/login');
  };

  let customerName = user?.username.trim().split(' ')?.[0] ?? user?.username;

  if (customerName?.length > 7) {
    customerName = `${customerName.slice(0, 7)}...`;
  }
  return (
    <DropdownMenu onOpenChange={(open) => setDropdownOpen(open)}>
      <DropdownMenuTrigger
        className={cn(
          `flex justify-center items-center border-0 no-underline cursor-pointer px-6 py-3 text-white rounded-xl bg-primary`,
          className
        )}
      >
        <span className="text-sm md:text-lg flex me-2">
          <FiUser />
        </span>
        <span className="text-xs md:text-base mr-2">
          {t('common.welcome')}{' '}
          <span className="capitalize">{customerName}</span>!
        </span>
        {dropdownOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-fit border text-white border-solid bg-defaultBlackLight border-black/10">
        <DropdownMenuLabel className="text-sm py-2">
          {t('common.my')} Productly
        </DropdownMenuLabel>
        <DropdownMenuSeparator className="bg-white/10" />
        <DropdownMenuGroup>
          <NavLink to="/app" className="no-underline" rel="noreferrer">
            <DropdownMenuItem className="text-sm hover:cursor-pointer hover:focus:bg-primary hover:focus:text-white">
              <RiAiGenerate className="text-lg me-3" />
              {t('common.generateContent')}
            </DropdownMenuItem>
          </NavLink>
          <NavLink to="/account" className="no-underline" rel="noreferrer">
            <DropdownMenuItem className="text-sm hover:cursor-pointer hover:focus:bg-primary hover:focus:text-white">
              <MdAccountCircle className="text-lg me-3" />
              {t('common.myAccount')}
            </DropdownMenuItem>
          </NavLink>
          <DropdownMenuSeparator className="bg-white/10" />
          <NavLink to="#" className="no-underline" onClick={handleLogout}>
            <DropdownMenuItem className="text-sm hover:cursor-pointer hover:focus:bg-primary hover:focus:text-white">
              <RiShutDownLine className="text-lg me-3" />
              {t('common.logOut')}
            </DropdownMenuItem>
          </NavLink>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default MemberMenuButton;

/* eslint-disable react/no-danger */
import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';

import { RootState } from '@/shared/rootReducers';

interface ProductHeaderProps {
  name: string;
  utility: string;
  image_url?: string;
}

function ProductHeader({
  name,
  utility,
  image_url: imageUrl,
}: ProductHeaderProps) {
  const { currentProductId, products } = useSelector(
    (state: RootState) => state?.products
  );
  const currentProduct = products?.find(
    (product) => product.id === currentProductId
  );
  const { amazon_url: productUrl } = currentProduct ?? {};
  return (
    <div className="product-container">
      <div className="product-text">
        <h1 className="product-name-head">{name}</h1>
        <p>{utility}</p>
        <a
          href={productUrl}
          className="bg-transparent"
          aria-label="url"
          target="_blank"
          rel="noreferrer"
        >
          <FaExternalLinkAlt />
        </a>
      </div>
      {imageUrl && <img src={imageUrl} alt={name} className="product-image" />}
    </div>
  );
}

export default ProductHeader;

import { apiSlice } from './apiSlice';

import { setCredentials } from '../reducers/authReducer';

export interface UserResponse {
  error?: string;
  token?: string;
  code?: string;
}

export interface LoginRequest {
  email: string;
  password: string;
  recaptchaToken: string;
}

const authenticateSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    postLogin: builder.mutation<UserResponse, LoginRequest>({
      query: (credentials) => ({
        url: 'auth/login',
        method: 'POST',
        body: credentials,
      }),
    }),
    getGenerateNewToken: builder.query<any, void>({
      query: () => ({
        url: `generate-new-token`,
        method: 'GET',
      }),
      onQueryStarted: async (_, api) => {
        const { dispatch, queryFulfilled } = api;
        const { data } = await queryFulfilled;
        if (data?.token) {
          dispatch(setCredentials({ token: data.token }));
        }
      },
    }),
  }),
});

// eslint-disable-next-line import/prefer-default-export
export const {
  usePostLoginMutation,
  useLazyGetGenerateNewTokenQuery,
  useGetGenerateNewTokenQuery,
} = authenticateSlice;

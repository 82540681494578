import React from 'react';

import Skeleton from './Skeleton';

import { cn } from '@/lib/utils';

function TextLoadingSkeleton({ className }: any) {
  return (
    <div className="w-full space-y-2 opacity-20">
      <Skeleton className={cn('w-full h-2', className)} />
      <Skeleton className={cn('w-full h-2', className)} />
      <Skeleton className={cn('w-full h-2', className)} />
      <Skeleton className={cn('w-10/12 h-2', className)} />
      <Skeleton className={cn('w-10/12 h-2', className)} />
      <Skeleton className={cn('w-8/12 h-2', className)} />
    </div>
  );
}

export default TextLoadingSkeleton;

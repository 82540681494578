import find from 'lodash/find';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdAddShoppingCart } from 'react-icons/md';

import Button from '@/components/common/Button';
import EditableText from '@/components/EditableText';
import GroupDeletable from '@/components/GroupDeletable/GroupDeletable';

interface ImportProductCallToActionProps {
  fields: Record<string, any>[];
  onInputChange: ({ value, id, name }: any) => void;
}

function ImportProductCallToAction({
  fields,
  onInputChange,
}: ImportProductCallToActionProps) {
  const { t } = useTranslation();
  const callToActionTitle: any = find(fields, { name: 'callToActionTitle' });
  const callToActionContent: any = find(fields, {
    name: 'callToActionContent',
  });
  return (
    <GroupDeletable groupId={callToActionContent?.id}>
      <div className="mt-20 flex max-w-[300px] flex-col items-center justify-center sm:max-w-[760px]">
        <div className="w-full">
          <EditableText onInputChange={onInputChange} {...callToActionTitle} />
        </div>
        <div className="w-full">
          <EditableText
            onInputChange={onInputChange}
            {...callToActionContent}
          />
        </div>
        <Button
          className="bg-lime-600 hover:bg-lime-600/80 py-4 px-8 text-white border-0 rounded-full text-2xl mt-10"
          text={t('shopify.buyNow')}
          icon={<MdAddShoppingCart className="mr-4" />}
          iconPosition="before"
        />
      </div>
    </GroupDeletable>
  );
}

export default ImportProductCallToAction;

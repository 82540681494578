/* eslint-disable react-hooks/exhaustive-deps */
import _find from 'lodash/find';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Label } from '../common/Label';
import { RadioGroup, RadioGroupItem } from '../common/shadcn-ui/RadioGroup';

import { setCancellationState } from '@/shared/reducers/cancellationReducer';
import { RootState } from '@/shared/reduxStore';

type RadioSettingProps = {
  id: string;
  label: string;
};

function HowOfften() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { howOfften = '' } = useSelector(
    (state: RootState) => state.cancellation
  );
  const radiosSettings: RadioSettingProps[] = [
    {
      id: 'daily',
      label: t('cancelation.daily'),
    },
    {
      id: 'weekly',
      label: t('cancelation.fewTimeAWeek'),
    },
    {
      id: 'monthly',
      label: t('cancelation.fewTimeAMonth'),
    },
    {
      id: 'little',
      label: t('cancelation.hardly'),
    },
  ];

  const handleOptionChange = (value: string) => {
    dispatch(
      setCancellationState({
        howOfften: value,
      })
    );
  };

  useEffect(() => {
    dispatch(setCancellationState({ stepperNextBtnDisable: howOfften === '' }));
  }, [howOfften]);

  return (
    <RadioGroup
      defaultValue={howOfften}
      className="text-white"
      onValueChange={handleOptionChange}
    >
      {radiosSettings.map(({ id, label }) => (
        <div className="flex items-center space-x-2">
          <RadioGroupItem value={id} id={id} />
          <Label htmlFor={id}>{label}</Label>
        </div>
      ))}
    </RadioGroup>
  );
}

export default HowOfften;

import Button from 'components/common/Button';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/common/Card';
import { Input } from '@/components/common/Input';
import { Label } from '@/components/common/Label';

function ShopifyConnectSimple() {
  const { t } = useTranslation();
  const [shopName, setShopName] = useState<string | null>(null);
  return (
    <Card>
      <CardHeader>
        <CardTitle className="m-0">{t('shopify.connectYourShopify')}</CardTitle>
        <CardDescription className="text-xs">
          {t('shopify.connectYourShopifySubTitle')}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="grid w-full items-center gap-4">
          <div className="flex flex-col space-y-1.5 mt-5">
            <Label htmlFor="name">{t('shopify.enterYourShopifyAddress')}</Label>
            <Label
              className="font-normal text-base w-full flex items-end pt-3"
              htmlFor="shopify-name"
            >
              <Input
                className="p-0 m-0 border-0 outline-none text-sm border-b border-zinc-400 w-8/12 border-dotted bg-white bg-opacity-10 empty:before:italic empty:before:text-zinc-500 text-black rounded-none indent-0 h-auto placeholder:text-zinc-500"
                onBlur={(event) => {
                  const { value } = event.currentTarget;
                  setShopName(value ?? null);
                }}
                placeholder={t('shopify.enterYourShopifyAddressPlaceholder')}
              />
              <i className="text-sm">.myshopify.com</i>
            </Label>
          </div>
        </div>
      </CardContent>
      <CardFooter className="flex justify-end mt-5">
        <Button
          onClick={async () => {
            if (!shopName) return;
            window.location.href = `${process.env.VITE_APP_API_URL}/shopify/auth?shop=${shopName}&token=${localStorage.getItem('productly')}`;
          }}
          className="border-none bg-primary px-4 text-sm rounded-sm py-2 hover:bg-shopify text-white transition-all"
        >
          {t('common.connect')}
        </Button>
      </CardFooter>
    </Card>
  );
}

export default ShopifyConnectSimple;

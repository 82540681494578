const countryUsingEUR = [
  'at',
  'be',
  'hr',
  'cy',
  'cz',
  'dk',
  'ee',
  'fi',
  'de',
  'gr',
  'hu',
  'ie',
  'it',
  'lv',
  'lt',
  'lu',
  'mt',
  'mc',
  'nl',
  'pt',
  'ro',
  'sk',
  'si',
  'es',
  'gb',
];
export default countryUsingEUR;

export const currencySign: Record<string, any> = {
  eur: '€',
  usd: '$',
  gbp: '£',
  cad: 'C$',
  chf: 'CHF',
  aud: 'A$',
  jpy: '¥',
  cny: '¥',
  brl: 'R$',
  rub: '₽',
  inr: '₹',
  mxn: '$',
  krw: '₩',
  idr: 'Rp',
  try: '₺',
  sar: 'SAR',
  zar: 'R',
  sek: 'kr',
  pln: 'zł',
};

export const currencySupport: Record<string, any> = {
  fr: 'eur',
  eu: 'eur',
  us: 'usd',
  gb: 'gbp',
  ca: 'cad',
  ch: 'chf',
  au: 'aud',
  jp: 'jpy',
  cn: 'cny',
  br: 'brl',
  ru: 'rub',
  in: 'inr',
  mx: 'mxn',
  kr: 'krw',
  id: 'try',
  sa: 'sar',
  za: 'zar',
  se: 'sek',
  pl: 'pln',
};

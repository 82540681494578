/* eslint-disable import/prefer-default-export */
export const subscriptionPlans = [
  {
    name: 'Starter',
    monthlyPrice: {
      eur: 19.99,
      usd: 21.99,
      gbp: 17.99,
      cad: 27.99,
      chf: 19.99,
      aud: 30.99,
      jpy: 3099,
      cny: 149.99,
      brl: 99.99,
      rub: 1999,
      inr: 1699,
      mxn: 349.99,
      krw: 27999,
      idr: 309999,
      try: 569.99,
      sar: 76.99,
      zar: 379.99,
      sek: 224.99,
      pln: 89.99,
    },
    annualPrice: {
      eur: 9.99,
      usd: 10.66,
      gbp: 8.74,
      cad: 13.33,
      chf: 10.83,
      aud: 14.99,
      jpy: 1633.25,
      cny: 79.99,
      brl: 48.33,
      rub: 999.91,
      inr: 999.91,
      mxn: 174.16,
      krw: 14083.25,
      idr: 126666.59,
      try: 257.49,
      sar: 39.99,
      zar: 198.39,
      sek: 114.99,
      pln: 45.83,
    },
    features: [
      3,
      true,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
  },
  {
    name: 'Standard',
    monthlyPrice: {
      eur: 29.99,
      usd: 32.99,
      gbp: 26.99,
      cad: 42.99,
      chf: 29.99,
      aud: 46.99,
      jpy: 4699,
      cny: 224.99,
      brl: 149.99,
      rub: 3099,
      inr: 2599,
      mxn: 539.99,
      krw: 41999,
      idr: 474999,
      try: 869.99,
      sar: 116.99,
      zar: 579.99,
      sek: 344.99,
      pln: 134.99,
    },
    annualPrice: {
      eur: 14.99,
      usd: 15.83,
      gbp: 13.08,
      cad: 19.99,
      chf: 16.24,
      aud: 22.49,
      jpy: 2449.91,
      cny: 119.99,
      brl: 72.49,
      rub: 1499.91,
      inr: 1499.91,
      mxn: 261.66,
      krw: 21166.58,
      idr: 189999.91,
      try: 387.49,
      sar: 59.99,
      zar: 298.33,
      sek: 171.66,
      pln: 68.74,
    },
    features: [
      10,
      true,
      true,
      true,
      true,
      true,
      true,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
  },
  {
    name: 'Pro',
    monthlyPrice: {
      eur: 59.99,
      usd: 63.99,
      gbp: 52.99,
      cad: 86.99,
      chf: 58.99,
      aud: 93.99,
      jpy: 9499,
      cny: 459.99,
      brl: 309.99,
      rub: 6199,
      inr: 5299,
      mxn: 1089.99,
      krw: 84999,
      idr: 964999,
      try: 1769.99,
      sar: 237.99,
      zar: 1179.99,
      sek: 694.99,
      pln: 274.99,
    },
    annualPrice: {
      eur: 29.99,
      usd: 31.66,
      gbp: 26.16,
      cad: 39.99,
      chf: 32.49,
      aud: 44.99,
      jpy: 4899.91,
      cny: 239.99,
      brl: 144.99,
      rub: 2999.91,
      inr: 2999.91,
      mxn: 523.33,
      krw: 42333.25,
      idr: 379999.91,
      try: 774.999,
      sar: 119.999,
      zar: 596.666,
      sek: 343.333,
      pln: 137.49,
    },
    features: [
      50,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
    ],
  },
  {
    name: 'Lifetime',
    originalPrice: {
      eur: 599.99,
      usd: 644.99,
      gbp: 517.99,
      cad: 874.99,
      chf: 637.49,
      aud: 942.49,
      jpy: 92997.59,
      cny: 4589.99,
      brl: 2999.99,
      rub: 61997.9,
      inr: 53497.9,
      mxn: 10949.99,
      krw: 854997.9,
      idr: 9699997.9,
      try: 17474.99,
      sar: 2374.99,
      zar: 11749.99,
      sek: 6849.99,
      pln: 2724.99,
    },
    discountPrice: {
      eur: 239.99,
      usd: 257.99,
      gbp: 206.99,
      cad: 349.99,
      chf: 254.99,
      aud: 376.99,
      jpy: 37199,
      cny: 1835.99,
      brl: 1199.99,
      rub: 24799,
      inr: 21399,
      mxn: 4379.99,
      krw: 341999,
      idr: 3879999,
      try: 6989.99,
      sar: 949.99,
      zar: 4699.99,
      sek: 2739.99,
      pln: 1089.99,
    },
    features: [
      9999,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
    ],
  },
];
